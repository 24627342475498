<template>
  <div>
    <form id="ttal-form" action="" @submit.prevent="onSubmit()" novalidate>
      <div
        class="bg-nColorFillGray border border-nColorStrokeGray rounded-xl px-6 py-6 text-black w-full"
      >
        <div class="experience-info">
          <div class="flex justify-between items-end">
            <h2 class="font-semibold text-lg inline-block">
              Create your own Three Truths and a Lie by adding your statements
              and the lie.
            </h2>
          </div>
          <div
            class="statement-set mt-4 bg-nColorDarkGray border border-nColorStrokeGray rounded-xl flex"
            v-for="index in statementSetCount"
            :key="index"
          >
            <div class="m-4 w-full">
              <div class="flex flex-row">
                <div class="title w-1/4">
                  <label for="title" class="font-semibold block required-field"
                    >Title</label
                  >
                  <input
                    type="text"
                    class="textInput"
                    name="title"
                    id="title"
                    v-model="statements[index - 1].title"
                    placeholder="Title"
                    required
                  />
                  <div
                    class="text-sm text-red-500 mt-3"
                    v-if="
                      v$.statements[index - 1].title.$invalid && checkValidation
                    "
                  >
                    {{
                      v$.statements[index - 1].title.$silentErrors[0].$message
                    }}
                  </div>
                </div>
                <div class="description w-2/4 ml-4">
                  <label for="description" class="font-semibold block"
                    >Description</label
                  >
                  <textarea
                    class="textInput w-3/5"
                    name="description"
                    id="description"
                    cols="30"
                    rows="2"
                    v-model="statements[index - 1].description"
                  ></textarea>
                </div>
                <div class="w-1/4 flex place-content-end">
                  <button
                    @click.prevent="deleteStatementSet(index - 1)"
                    class="w-24 h-12 transition border border-red-600 shadow duration-300 ease-out
                         tracking-wider bg-white text-red-600 py-3 rounded-lg uppercase font-bold
                         hover:bg-red-600 hover:text-white focus:outline-none"
                  >
                    Remove
                  </button>
                </div>
              </div>
              <label
                for="description"
                class="font-semibold block mt-4 required-field"
                >Statements</label
              >
              <div class="statements mt-2">
                <div class="flex flex-row gap-4">
                  <div class="statement">
                    <div class="flex">
                      <label
                        for="statement"
                        class="font-semibold bg-nColorBlue text-white py-3.5 px-3 rounded-l-lg"
                        >0 1</label
                      >
                      <input
                        type="text"
                        class="textInputTtal"
                        name="statement"
                        id="statement1"
                        v-model="statements[index - 1].statement1"
                        placeholder="Enter statement"
                        required
                      />
                    </div>
                    <div
                      class="text-sm text-red-500 mt-3"
                      v-if="
                        v$.statements[index - 1].statement1.$invalid &&
                          checkValidation
                      "
                    >
                      {{
                        v$.statements[index - 1].statement1.$silentErrors[0]
                          .$message
                      }}
                    </div>
                  </div>
                  <div class="statement">
                    <div class="flex">
                      <label
                        for="statement"
                        class="font-semibold bg-nColorBlue text-white py-3.5 px-3 rounded-l-lg"
                        >0 2</label
                      >
                      <input
                        type="text"
                        class="textInputTtal"
                        name="statement"
                        id="statement2"
                        v-model="statements[index - 1].statement2"
                        placeholder="Enter statement"
                        required
                      />
                    </div>
                    <div
                      class="text-sm text-red-500 mt-3"
                      v-if="
                        v$.statements[index - 1].statement2.$invalid &&
                          checkValidation
                      "
                    >
                      {{
                        v$.statements[index - 1].statement2.$silentErrors[0]
                          .$message
                      }}
                    </div>
                  </div>
                </div>
                <div class="flex flex-row gap-4 mt-4">
                  <div class="statement">
                    <div class="flex">
                      <label
                        for="statement"
                        class="font-semibold bg-nColorBlue text-white py-3.5 px-3 rounded-l-lg"
                        >0 3</label
                      >
                      <input
                        type="text"
                        class="textInputTtal"
                        name="statement"
                        id="statement3"
                        v-model="statements[index - 1].statement3"
                        placeholder="Enter statement"
                        required
                      />
                    </div>
                    <div
                      class="text-sm text-red-500 mt-3"
                      v-if="
                        v$.statements[index - 1].statement3.$invalid &&
                          checkValidation
                      "
                    >
                      {{
                        v$.statements[index - 1].statement3.$silentErrors[0]
                          .$message
                      }}
                    </div>
                  </div>
                  <div class="statement">
                    <div class="flex">
                      <label
                        for="statement"
                        class="font-semibold bg-nColorBlue text-white py-3.5 px-3 rounded-l-lg"
                        >0 4</label
                      >
                      <input
                        type="text"
                        class="textInputTtal w-2/3"
                        name="statement"
                        id="statement4"
                        v-model="statements[index - 1].statement4"
                        placeholder="Enter statement"
                        required
                      />
                    </div>
                    <div
                      class="text-sm text-red-500 mt-3"
                      v-if="
                        v$.statements[index - 1].statement4.$invalid &&
                          checkValidation
                      "
                    >
                      {{
                        v$.statements[index - 1].statement4.$silentErrors[0]
                          .$message
                      }}
                    </div>
                  </div>
                </div>
              </div>
              <div class="lie mt-8">
                <p class="font-semibold block mb-2 required-field">
                  Select the lie
                </p>
                <div class="lie-selector flex flex-wrap">
                  <div class="mr-6 mb-2">
                    <input
                      class="w-4 h-4 relative top-0.5"
                      type="radio"
                      :name="'lie' + (index - 1)"
                      :id="`statement${index - 1}1Lie`"
                      v-model="statements[index - 1].lie"
                      value="statement1"
                    />
                    <label :for="`statement${index - 1}1Lie`" class=" ml-1"
                      >Statement 1</label
                    >
                  </div>
                  <div class="mr-6 mb-2">
                    <input
                      class="w-4 h-4 relative top-0.5"
                      type="radio"
                      :name="'lie' + (index - 1)"
                      :id="`statement${index - 1}2Lie`"
                      v-model="statements[index - 1].lie"
                      value="statement2"
                    />
                    <label :for="`statement${index - 1}2Lie`" class=" ml-1"
                      >Statement 2</label
                    >
                  </div>
                  <div class="mr-6 mb-2">
                    <input
                      class="w-4 h-4 relative top-0.5"
                      type="radio"
                      :name="'lie' + (index - 1)"
                      :id="`statement${index - 1}3Lie`"
                      v-model="statements[index - 1].lie"
                      value="statement3"
                    />
                    <label :for="`statement${index - 1}3Lie`" class=" ml-1"
                      >Statement 3</label
                    >
                  </div>
                  <div class="mr-6 mb-2">
                    <input
                      class="w-4 h-4 relative top-0.5"
                      type="radio"
                      :name="'lie' + (index - 1)"
                      :id="`statement${index - 1}4Lie`"
                      v-model="statements[index - 1].lie"
                      value="statement4"
                    />
                    <label :for="`statement${index - 1}4Lie`" class=" ml-1"
                      >Statement 4</label
                    >
                  </div>
                </div>
                <div
                  class="text-sm text-red-500 mt-3"
                  v-if="
                    v$.statements[index - 1].lie.$invalid && checkValidation
                  "
                >
                  {{ v$.statements[index - 1].lie.$silentErrors[0].$message }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="flex justify-end mt-3">
          <button
            @click.prevent="addStatementSet()"
            class="transition shadow duration-300 ease-out tracking-wider bg-nButtonGreen text-white px-4 py-2 rounded-lg uppercase font-bold
                   focus:outline-none"
          >
            Add another statement
          </button>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";

export default {
  props: ["expDetails", "experience_id", "onboarding_kit_id"],
  setup() {
    return { v$: useVuelidate() };
  },
  data() {
    return {
      availability: null,
      statements: [
        {
          title: null,
          statement1: null,
          statement2: null,
          statement3: null,
          statement4: null,
          lie: null,
          description: null,
        },
      ],
      checkValidation: false,
      validEndDate: true,
      statementSetCount: 1,
    };
  },
  validations() {
    let statementValidations = [];
    for (let i = 0; i < this.statementSetCount; i++) {
      statementValidations.push({
        title: { required },
        statement1: { required },
        statement2: { required },
        statement3: { required },
        statement4: { required },
        lie: { required },
      });
    }
    return {
      statements: statementValidations,
    };
  },
  created() {
    if (this.expDetails["exp_data"]["added_data"]) {
      this.statements = this.expDetails["exp_data"]["added_data"]["statements"];
      this.statementSetCount = this.expDetails["exp_data"]["added_data"][
        "statements"
      ].length;
    }
  },
  mounted() {},
  methods: {
    addStatementSet() {
      this.checkValidation = false;
      this.statements.push({
        statement1: null,
        statement2: null,
        statement3: null,
        statement4: null,
        lie: null,
        title: null,
        description: null,
      });
      this.statementSetCount++;
    },
    deleteStatementSet(index) {
      if (this.statements.length > 1) {
        this.statements.splice(index, 1);
        this.statementSetCount--;
      }
    },
    updateExpData() {
      this.checkValidation = true;
      if (!this.v$.$invalid && this.validEndDate) {
        this.$emit("expData", this.statements);
      } else {
        this.$emit("expData", null);
      }
    },
  },
  computed: {},
};
</script>
<style scoped lang="postcss">
.textInput {
  @apply bg-white rounded-lg text-black px-4 py-3 shadow border border-gray-600 focus:outline-none focus:border-gray-500 w-full;
}
.textInputTtal {
  @apply bg-white rounded-r-lg text-black p-3 shadow border border-nColorBlue focus:outline-none focus:border-blue-500 w-96;
}
</style>
