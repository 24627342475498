<template>
  <div>
    <div id="nhaOptions" class="mt-2 card text-black">
      <h2 class="text-2xl font-semibold">
        Hover on a thumbnail to preview and click to select an option.
      </h2>
      <div class="flex" v-if="expDetails && expDetails.exp_data">
        <div
          class="option-box"
          v-for="option in expDetails.exp_data.options"
          :key="option.slug"
        >
          <div class="selector mr-5">
            <input
              class="w-4 h-4 absolute opacity-0"
              type="radio"
              name="option"
              :id="'option_' + option.slug"
              :value="option.slug"
              required
              v-model="selectedOption"
            />
            <label
              @click.prevent="assignOption(option)"
              class="ml-3 text-lg font-semibold cursor-pointer"
              :for="'option_' + option.slug"
            >
              <div class="w-72 relative">
                <div
                  v-show="selectedOption.slug === option.slug"
                  class="absolute -top-4 -right-4 text-green-600 bg-white cursor-default rounded-full"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="currentColor"
                    class="w-8 bi bi-x-circle-fill"
                    viewBox="0 0 16 16"
                  >
                    <path
                      d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z"
                    />
                  </svg>
                </div>
                <img
                  :src="option.preview"
                  :class="{
                    'border-green-600': selectedOption.slug === option.slug,
                  }"
                  class="w-full rounded-lg border-2 border-gray-500"
                />
              </div>
              <span class="inline-block mt-2">{{ option.name }}</span>
              <a
                class="float-right mt-2 text-base text-nColorBlue"
                @click.prevent="togglePreview()"
                >Preview</a
              >
            </label>
          </div>
        </div>
      </div>
    </div>
    <div id="preview" class="hidden">
      <div
        class="w-full border border-nColorStrokeGray rounded-xl text-black p-4"
      >
        <button
          class="back-button text-lg uppercase font-semibold text-gray-400 mb-4"
          @click.prevent="togglePreview()"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="h-6 w-6 inline-block mr-1"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M10 19l-7-7m0 0l7-7m-7 7h18"
            />
          </svg>
          Back to description
        </button>
        <Option1
          v-if="selectedOption.slug === slugsConfig.nhaOptions.option1"
        />
        <Option2
          v-if="selectedOption.slug === slugsConfig.nhaOptions.option2"
        />
      </div>
    </div>
  </div>
</template>

<script>
import Option1 from "@/components/OnboardingKit/Experiences/NHA/Previews/Option1.vue";
import Option2 from "@/components/OnboardingKit/Experiences/NHA/Previews/Option2.vue";
import slugsConfig from "@/common/slugs.config";

export default {
  props: ["expDetails", "experience_id", "onboarding_kit_id"],
  components: { Option1, Option2 },
  created() {
    var addedData = this.expDetails.exp_data.added_data;
    if (addedData) {
      this.selectedOption.slug = addedData.option;
    }
  },
  data() {
    return {
      slugsConfig,
      selectedOption: {
        slug: "",
        name: null,
        type: null,
        preview: null,
        video: null,
        audio: null,
      },
    };
  },

  computed: {},
  methods: {
    assignOption(option) {
      this.selectedOption = option;
      this.$emit("expData", {
        option: this.selectedOption.slug,
      });
    },
    togglePreview() {
      let nhaOptions = document.querySelector("#nhaOptions");
      let preview = document.querySelector("#preview");
      if (preview.classList.contains("hidden")) {
        preview.classList.remove("hidden");
        nhaOptions.classList.add("hidden");
      } else {
        preview.classList.add("hidden");
        nhaOptions.classList.remove("hidden");
      }
    },
  },
};
</script>
<style scoped lang="postcss">
.card {
  @apply bg-nColorFillGray border border-nColorStrokeGray rounded-lg p-4 w-full text-black;
}
</style>
