<template>
  <div class="grid-item">
    <div
      class="upload-box-container border inline-block border-gray-200 p-3 rounded-full"
    >
      <div
        :class="{
          yellow: color === 'yellow',
          purple: color === 'purple',
          green: color === 'green',
          pink: color === 'pink',
        }"
        class="round-box rounded-full"
      >
        <div
          class="box-content h-full text-color text-center text-sm md:text-xl flex flex-col items-center justify-center"
        >
          <slot />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "NHACircleContainer",
  props: {
    color: {
      required: true,
    },
  },
};
</script>

<style scoped>
.round-box {
  width: 280px;
  height: 280px;
}

input {
  transition: all 500ms ease-in-out;
}

.green {
  background-color: #e4f7f4;
}

.yellow {
  background-color: #fff8de;
}

.purple {
  background-color: #e9ecff;
}
.pink {
  background-color: #fff0f5;
}

.text-color {
  color: #777777;
}

@media (min-width: 480px) {
  .round-box {
    width: 400px;
    height: 400px;
  }
}

@media (min-width: 768px) {
  .round-box {
    width: 420px;
    height: 420px;
  }
}

@media (min-width: 1400px) {
}

@media (min-width: 1600px) {
  .round-box {
    width: 450px;
    height: 450px;
  }
}
</style>
