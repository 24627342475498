<template>
  <div class="relative w-full lg:w-3/4">
    <div
      class="border-nColorStrokeGray border w-full absolute top-1/2 hidden md:block"
    ></div>
    <ul class="flex flex-wrap text-md font-semibold w-full justify-between">
      <li v-for="(step, index) in steps" :key="index">
        <div
          class="bg-nButtonBlue text-white px-8 py-1 rounded-full relative my-4"
          v-if="isStepSelected(step)"
        >
          <div
            class="absolute w-12 h-12 text-nButtonBlue border-8 border-nButtonBlue
                            top-1/2 left-0 text-xl transform -translate-y-1/2
                            rounded-full font-bold bg-white text-center text-progessActive"
          >
            <div class="mt-0.5">{{ index + 1 }}</div>
          </div>
          <span class="inline-block ml-6">
            {{ step }}
          </span>
        </div>
        <div
          v-else
          class="bg-gray-200 text-gray-500 pr-10 pl-6 py-1 rounded-full relative my-4 cursor-default"
        >
          <div
            class="absolute w-8 h-8 text-white
                            top-1/2 -left-0.5 text-xl transform -translate-y-1/2
                            rounded-full font-bold bg-nButtonBlue text-center"
          >
            <div class="mt-0.5">{{ index + 1 }}</div>
          </div>
          <span class="inline-block ml-5">
            {{ step }}
          </span>
        </div>
      </li>
    </ul>
  </div>
</template>
<script>
export default {
  props: {
    steps: {
      type: Array,
      required: true,
    },
    selected: {
      type: String,
      required: true,
    },
  },
  methods: {
    isStepSelected(step) {
      return step.toLowerCase() == this.selected.toLowerCase();
    },
  },
};
</script>

<style scoped></style>
