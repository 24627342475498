<template>
  <div
    class="bg-white border border-nColorStrokeGray rounded-xl text-black w-full mt-0 px-4 py-2
                flex"
    v-if="categories"
  >
    <div class="pt-1 font-semibold">Add this experience to</div>
    <div
      v-for="item in categories"
      :key="item.id"
      class="flex pl-4 items-center"
    >
      <input
        type="checkbox"
        name="exp-category"
        :checked="
          expDetails.categories.includes(item.id) ||
            expDetails.exp_data.added_categories.includes(item.id)
        "
        :id="'exp-cat' + item.id"
        @change="toggleExpCategory(item.id)"
        :disabled="!expDetails.flags.flexible_category"
      />
      <label class="pl-1 pt-1 font-medium" :for="'exp-cat' + item.id">{{
        item.name
      }}</label>
    </div>
  </div>
</template>

<script>
import { useToast } from "vue-toastification";

const toast = useToast();

export default {
  props: ["categories", "expDetails", "experience_id", "onboarding_kit_id"],
  created() {},
  mounted() {},
  data() {
    return {};
  },
  methods: {
    toggleExpCategory(id) {
      var catCount = 0;
      for (var i = 0; i < this.categories.length; i++) {
        if (
          document.querySelector("#exp-cat" + this.categories[i].id).checked
        ) {
          catCount++;
        }
      }
      if (catCount == 0) {
        document.querySelector("#exp-cat" + id).checked = true;
        toast.error("Please select atleast one journey for the experience.", {
          timeout: 3000,
        });
      } else {
        this.$emit("expCategory", {
          id: id,
          checked: document.querySelector("#exp-cat" + id).checked,
        });
      }
    },
    updateCategoryToggles() {
      var cats = [];
      for (var i = 0; i < this.categories.length; i++) {
        cats.push({
          id: this.categories[i].id,
          checked: document.querySelector("#exp-cat" + this.categories[i].id)
            .checked,
        });
      }
      this.$emit("categoryToggles", cats);
    },
  },
  computed: {},
};
</script>
<style scoped></style>
