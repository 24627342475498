<template>
  <div class="video-wrapper">
    <div class="video-container" id="video-container">
      <video
        :id="identifier"
        controls
        class="rounded-xl w-full border border-black"
        :src="src"
        :poster="poster"
        v-if="poster"
      ></video>
      <video
        :id="identifier"
        controls
        class="rounded-xl w-full border border-black"
        :src="src"
        v-else
      ></video>
      <div :id="identifier + 'PlayButtonWrapper'" class="play-button-wrapper">
        <div
          title="Play video"
          class="play-gif"
          :id="identifier + 'circleplayb'"
        >
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 80 80">
            <path
              d="M40 0a40 40 0 1040 40A40 40 0 0040 0zM26 61.56V18.44L64 40z"
            />
          </svg>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "VideoPlayer",
  props: {
    identifier: {
      type: String,
      required: true,
    },
    src: {
      type: String,
      required: true,
    },
    poster: {
      type: String,
      default: () => null,
    },
  },
  mounted() {
    const video = document.getElementById(this.identifier);
    const circlePlayButton = document.getElementById(
      this.identifier + "circleplayb"
    );
    const playButtonWrapper = document.getElementById(
      this.identifier + "PlayButtonWrapper"
    );
    if (circlePlayButton) {
      circlePlayButton.addEventListener("click", this.togglePlay);
      video.addEventListener("playing", function() {
        playButtonWrapper.style.opacity = 0;
      });
      video.addEventListener("pause", function() {
        playButtonWrapper.style.opacity = 1;
      });
    }
  },
  methods: {
    togglePlay() {
      const video = document.getElementById(this.identifier);
      if (video.paused || video.ended) {
        video.play();
      } else {
        video.pause();
      }
    },
  },
};
</script>

<style scoped>
.video-container {
  border-radius: 4px;
  margin: 0 auto;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-radius: 0.75rem;
}
.video-container .video-wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0.75rem;
}
.video-container video {
  width: 100%;
  height: 100%;
}

.play-button-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: auto;
  pointer-events: none;
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 0.75rem;
}
.play-button-wrapper > .play-gif {
  cursor: pointer;
  pointer-events: auto;
}
.play-button-wrapper > .play-gif svg {
  width: 60px;
  height: 60px;
  fill: #ffffff;
  stroke: #ffffff;
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0.3);
  border-radius: 50%;
  opacity: 0.9;
}
</style>
