<template>
  <div class="evp-graph h-full w-full">
    <div ref="chart" id="chart"></div>
  </div>
</template>

<script>
import d3 from "d3";

export default {
  name: "ObkEVPVisual",
  props: {
    evpData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      nodes2: [],
      root: null,
      force: null,
      link: null,
      node: null,
      parentschildren: [],
      linkedByIndex: {},
      x: null,
      colors: {
        culture: "11bca2",
        benefits: "ff5670",
        offboarding: "3d50e0",
        onboarding: "89023e",
        career: "3f88c5",
        compensation: "476a6f",
        organization: "a36fc3",
        work_environment: "F7B150",
        retiral_and_benefits: "F7B150",
      },
      selectionColor: "#000",
    };
  },
  mounted() {
    const vue = this;
    const width = this.$refs.chart.clientWidth;
    const height = 700;

    this.x = d3.scale.ordinal().rangeRoundBands([0, width], 100, 100);

    this.force = d3.layout
      .force()
      .linkDistance(250)
      .charge(-2000)
      .gravity(1.2)
      .size([width, height])
      .on("tick", tick);

    const svg = d3
      .select("#chart")
      .append("svg")
      .attr("width", width)
      .attr("height", height);

    this.link = svg.selectAll(".link");
    this.node = svg.selectAll(".node");

    this.initializeD3(this.dynamicSizes(this.evpData));
    this.simulateClick();

    function tick() {
      // sets the bounding box
      vue.node
        .attr("cx", function(d) {
          return (d.x = Math.max(50, Math.min(width - 50, d.x)));
        })
        .attr("cy", function(d) {
          return (d.y = Math.max(50, Math.min(height - 50, d.y)));
        });

      vue.link
        .attr("x1", function(d) {
          return d.source.x;
        })
        .attr("y1", function(d) {
          return d.source.y;
        })
        .attr("x2", function(d) {
          return d.target.x;
        })
        .attr("y2", function(d) {
          return d.target.y;
        });

      vue.node.attr("transform", function(d) {
        return "translate(" + d.x + "," + d.y + ")";
      });
    }
    svg.selectAll("text").call(this.wrap);
  },

  methods: {
    initializeD3(root) {
      this.root = root;
      this.update(this.root);
    },

    simulateClick() {
      const newNodes = [];
      for (let i = this.nodes2.length; i >= 0; i--) {
        newNodes.push(this.nodes2[i]);
      }

      for (let i = 0; i < newNodes.length; i++) {
        if (newNodes[i]) {
          this.d3Click(document.querySelectorAll("#" + newNodes[i]));
        }
      }

      this.d3Click(document.querySelectorAll("#morph"));
    },

    d3Click(args) {
      args.forEach((element) => {
        const event = new MouseEvent("click", {
          bubbles: true,
          cancelable: true,
          view: window,
          detail: 0,
        });

        element.dispatchEvent(event);

        event.stopPropagation();
      });
    },

    // Returns a list of all nodes under the root.
    flatten(root) {
      const vue = this;
      const nodes = [];
      let i = 0;

      function recurse(node) {
        if (node.children) {
          vue.nodes2.push(node.value);
          node.children.forEach(recurse);
        }
        if (!node.id) node.id = ++i;
        nodes.push(node);
      }

      recurse(root);

      return nodes;
    },

    update(d) {
      const nodes = this.flatten(d);
      const links = d3.layout.tree().links(nodes);

      // Restart the force layout.
      this.force
        .nodes(nodes)
        .links(links)
        .start();

      // Update links.
      this.link = this.link.data(links, function(d) {
        return d.target.id;
      });

      this.link.exit().remove();

      this.link
        .enter()
        .insert("line", ".node")
        .attr("class", "link");

      // Update nodes.
      this.node = this.node.data(nodes, function(d) {
        return d.id;
      });

      // Exit any old links.
      this.node.exit().remove();

      // Enter any new nodes.
      const nodeEnter = this.node
        .enter()
        .append("g")
        .attr("class", "node")
        .on("click", this.click)
        .call(this.force.drag);

      nodeEnter
        .append("svg:a")
        .append("circle")
        .attr("fill", this.color)
        .attr("id", function(d) {
          return d.value;
        })
        .attr("r", 10);

      nodeEnter
        .append("text")
        .attr("dx", 5)
        .attr("dy", 2)
        .text(function(d) {
          return d.name;
        });

      nodeEnter
        .append("image")
        .attr("x", "-10")
        .attr("y", "-30")
        .attr("href", this.linkIconURL)
        .attr("width", "20")
        .attr("height", "20")
        .style("display", function(d) {
          if (d.link) return "block";

          return "none";
        });
    },

    // Toggle children on click.
    click(d) {
      const vue = this;
      if (d3.event.defaultPrevented) return;

      if (!d.children) {
        if (d.link) {
          window.open(d.link, "_blank").focus();
        }
        return;
      }

      this.update(this.root);

      if (d.parentName === "none") {
        this.initializeD3(this.dynamicSizes(this.evpData));
      }

      // marks the clicked node
      d3.selectAll(".link")
        .transition()
        .duration(100)
        .style("stroke-width", function(o) {
          return o.target === d || o.target === d ? 4 : 2;
        })
        .style("stroke", function(o) {
          return o.target === d || o.target === d ? vue.selectionColor : "#ccc";
        })
        .style("display", function(o) {
          if (o.target === d) return "block";

          const childNames =
            d.children && d.children.map((child) => child.name);

          if (childNames && childNames.includes(o.target.name)) return "block";

          return "none";
        });

      d3.selectAll(".node")
        .transition()
        .duration(200)
        .style("stroke-width", function(o) {
          return vue.neighboring(d, o) ? 0 : 0;
        })
        .style("opacity", function(o) {
          if (o.parentName === "none") return 1;

          if (o === d) return 1;

          const childNames =
            d.children && d.children.map((child) => child.name);

          if (childNames && childNames.includes(o.name)) return 1;

          return 0;
        });
    },

    color(d) {
      return this.nodeColor(d);
    },

    neighboring(a, b) {
      return a.index == b.index || this.linkedByIndex[a.index + "," + b.index];
    },

    wrap(text) {
      text.each(function() {
        var text = d3.select(this),
          words = text
            .text()
            .split(/\s+/)
            .reverse(),
          word,
          line = [],
          lineNumber = 0,
          lineHeight = -0.5,
          y = text.attr("y"),
          dy = parseFloat(text.attr("dy")),
          tspan = text
            .text(null)
            .append("tspan")
            .attr("x", 0)
            .attr("y", y)
            .attr("dy", dy + "em");
        while ((word = words.pop())) {
          line.push(word);
          tspan.text(line.join(" "));

          if (tspan.node().getComputedTextLength() > 105) {
            line.pop();
            tspan.text(line.join(" "));
            line = [word];
            let offset = ++lineNumber * lineHeight;
            if (offset > 1) {
              offset = 1;
            }
            tspan = text
              .append("tspan")
              .attr("x", 0)
              .attr("y", y)
              .attr("dy", offset + dy + "em")
              .text(word);
          }
        }
      });
    },

    nodeColor(slug) {
      return `#${this.colors[slug.value] || this.colors[slug.parentName]}`;
    },

    dynamicSizes(root) {
      return {
        name: root.name,
        value: root.value,
        parentName: root.parentName,
        children: root.children.map((child) => {
          return {
            name: child.name,
            value: child.value,
            parentName: child.parentName,
            children: child.children.map((child) => {
              return {
                name: child.name,
                value: child.value,
                parentName: child.parentName,
                children: child.children,
                size: this.nodeSize(child.name.length, child.size),
                link: child.link,
              };
            }),
            size: this.nodeSize(child.name.length, child.size),
          };
        }),
        size: this.nodeSize(root.name.length, root.size),
      };
    },

    nodeSize(labelLength, size) {
      if (labelLength > 8) {
        let newSize = (labelLength - 8) * 80000 + size * 2;

        if (newSize > 1700000) newSize = 1700000;
        return newSize;
      }

      return size;
    },
  },
};
</script>

<style>
.node text {
  font-size: 13px;
  fill: #000;
  pointer-events: none;
  text-anchor: middle;
  font-weight: bold;
}
.node a,
.node image,
.node text {
  transition: all 300ms ease;
}
.node circle {
  cursor: pointer;
  stroke-width: 3px;
}

.node:hover text,
.node:hover image,
.node:hover a {
  transform: scale3d(1.2, 1.2, 1);
}

line.link {
  fill: none;
  stroke: #515151;
  stroke-width: 2px;
}

#chart {
  width: 100%;
  text-align: center;
  background: transparent;
}
</style>
