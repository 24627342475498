<template>
  <div
    class="text-white flex items-center"
    :class="{
      'justify-start': align === 'left',
      'justify-end': align === 'right',
    }"
  >
    <ButtonInput
      v-if="showAddButton"
      buttonText="Add"
      :loading="loading"
      :buttonClass="
        addButtonEnabled
          ? 'transition shadow duration-300 ease-out tracking-wider bg-nButtonGreen text-white px-6 py-2 rounded-lg uppercase font-semibold ml-4 outline-none focus:outline-none'
          : 'transition shadow duration-300 ease-out tracking-wider bg-gray-400 text-white px-6 py-2 rounded-lg uppercase font-semibold ml-4 outline-none focus:outline-none cursor-not-allowed'
      "
      :loadingClass="
        'transition shadow duration-300 ease-out tracking-wider bg-nButtonGreen text-white px-6 py-2 rounded-lg uppercase font-bold ml-4 outline-none focus:outline-none cursor-not-allowed'
      "
    />
    <ButtonInput
      v-else
      buttonText="Update"
      :loading="loading"
      buttonClass="transition shadow duration-300 ease-out tracking-wider bg-nButtonGreen text-white px-6 py-2 rounded-lg uppercase font-semibold ml-4 outline-none focus:outline-none"
      :loadingClass="
        'transition shadow duration-300 ease-out tracking-wider bg-nButtonGreen text-white px-6 py-2 rounded-lg uppercase font-bold ml-4 outline-none focus:outline-none cursor-not-allowed'
      "
    />
  </div>
</template>

<script>
import ButtonInput from "@/components/Elements/ButtonInput.vue";

export default {
  name: "FormButtons",
  props: {
    showAddButton: {
      type: Boolean,
      default: () => false,
    },
    loading: {
      type: Boolean,
      default: () => false,
    },
    order: {
      type: String,
      default: () => "first",
    },
    align: {
      type: String,
      default: () => "left",
    },
    disabled: {
      type: Boolean,
      default: () => false,
    },
    addButtonEnabled: {
      type: Boolean,
      default: () => true,
    },
  },
  components: {
    ButtonInput,
  },
};
</script>
<style scoped></style>
