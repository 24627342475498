<template>
  <div>
    <div class="evp-section py-4 px-6 border relative">
      <h3 class="text-3xl font-semibold">{{ category.name }}</h3>
      <div class="options h-96 overflow-y-auto ">
        <div class="option-cont">
          <button
            type="button"
            @click="clearValues()"
            class="absolute shadow right-2 top-2 text-red-500 font-semibold rounded border-red-400 border px-3 py-1
                  hover:bg-red-400 hover:text-white transition-all duration-300 ease-in-out uppercase focus:outline-none"
          >
            clear
          </button>

          <div class="mt-4 mb-8 select flex flex-wrap justify-start">
            <div
              class="mr-10 my-6"
              v-for="option in localCategory.options"
              :key="option"
            >
              <input
                class="hidden"
                type="checkbox"
                :name="localCategory.slug"
                :id="option"
                :value="option"
                v-model="selectedValues"
                :disabled="
                  selectedValues.length > maxSelectionCount - 1 &&
                    selectedValues.indexOf(option) === -1
                "
              />
              <label
                :class="{
                  ' border-nColorBlue': values.includes(option),
                }"
                class="bg-white py-2 border px-6 rounded-full cursor-pointer shadow hover:shadow-lg transition
                        duration-400 ease-out"
                :for="option"
                >{{ option }}</label
              >
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="custom" class="bottom-custom border mt-4 p-4">
      <form @submit.prevent="addCustomValue()" action="" class="add-custom">
        <div class="flex items-center justify-between">
          <div class="custom-input">
            <input
              required
              v-model="customValue"
              placeholder="Add custom"
              type="text"
              class="bg-white rounded-lg text-black px-4 py-3 shadow border border-gray-600 focus:outline-none focus:border-gray-500 w-72"
              maxlength="32"
            />
            <button
              class="px-3 font-semibold bg-nColorBlue text-white uppercase py-3 mx-2 rounded-xl shadow-md"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-6 w-6 inline"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M12 4v16m8-8H4"
                />
              </svg>
            </button>
          </div>
          <button
            type="button"
            @click="showPreview()"
            :class="{
              'pointer-events-none bg-gray-400': !checkSelections,
              'bg-nColorBlue': checkSelections,
            }"
            class="px-8 py-3 uppercase float-right font-semibold rounded-lg text-white"
          >
            Preview
          </button>
        </div>

        <p class="mt-2">
          <span class=" text-red-500 font-bold">*</span> Maximum Characters: 32
        </p>
      </form>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    category: { type: Object, required: true },
    values: { type: Array, required: true },
    custom: { type: Boolean, default: () => true },
    checkSelections: { type: Boolean, default: () => false },
  },
  created() {
    this.selectedValues = this.values;
    this.localCategory = this.category;
  },
  emits: ["goNext", "changeValue", "preview"],
  data() {
    return {
      selectedValues: [],
      customValue: "",
      localCategory: null,
      maxSelectionCount: 10,
    };
  },
  methods: {
    assignCategory(cat) {
      this.$emit("goNext", cat);
    },
    clearValues() {
      this.selectedValues = [];
    },
    addCustomValue() {
      if (!this.localCategory.options.includes(this.customValue)) {
        this.localCategory.options.unshift(this.customValue);
        if (this.selectedValues.length < this.maxSelectionCount) {
          this.selectedValues.push(this.customValue);
        }
        this.customValue = "";
      } else return;
    },
    showPreview() {
      this.$emit("preview");
    },
  },
  watch: {
    selectedValues: function() {
      if (this.selectedValues.length <= this.maxSelectionCount) {
        this.$emit("changeValue", {
          values: this.selectedValues,
          slug: this.category.slug,
        });
      } else {
        return;
      }
    },
  },
};
</script>

<style></style>
