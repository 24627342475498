<template>
  <div>
    <div class="mt-2 card">
      <h2 class="text-2xl font-semibold">
        Hover on a thumbnail to preview and click to select an option.
      </h2>
      <div class="flex" v-if="expDetails && expDetails.exp_data">
        <div
          class="option-box"
          v-for="option in expDetails.exp_data.options"
          :key="option.slug"
        >
          <div class="selector mr-5">
            <input
              class="w-4 h-4 absolute opacity-0"
              type="radio"
              name="option"
              :id="'option_' + option.slug"
              :value="option.slug"
              required
              v-model="selectedOption"
            />
            <label
              @click.prevent="assignOption(option)"
              class="ml-3 text-lg font-semibold cursor-pointer"
              :for="'option_' + option.slug"
            >
              <div class="w-72 relative">
                <div
                  v-show="selectedOption.slug === option.slug"
                  class="absolute -top-4 -right-4 text-green-600 bg-white cursor-default rounded-full"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="currentColor"
                    class="w-8 bi bi-x-circle-fill"
                    viewBox="0 0 16 16"
                  >
                    <path
                      d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z"
                    />
                  </svg>
                </div>
                <video
                  loop
                  @mouseenter="videoPlay($event)"
                  @mouseleave="videoPause($event)"
                  :class="{
                    'border-green-600': selectedOption.slug === option.slug,
                  }"
                  class="w-full rounded-lg border-2 border-gray-500"
                  :src="option.preview"
                  muted
                ></video>
              </div>
              <span class="inline-block mt-2">{{ option.name }}</span>
            </label>
          </div>
        </div>
      </div>
    </div>
    <div class="evp bg-white border rounded-xl text-black p-6 mt-2">
      <div class="evp-container flex">
        <div class="category-indicator w-1/4 mr-6">
          <div class="categories">
            <div class="category" v-for="cat in categories" :key="cat.slug">
              <div
                @click="gotoCat(cat.slug)"
                :class="{
                  'bg-blue-100': cat.slug === showCategory,
                }"
                class="flex cursor-pointer category-indicator-tile p-3 justify-between border border-blue-100"
              >
                <span
                  :class="{
                    'text-blue-800 font-bold': cat.slug === showCategory,
                  }"
                  class="text-xl text-blue-400"
                  >{{ cat.name }}</span
                >
                <svg
                  v-if="
                    selectedEVP[cat.slug] && selectedEVP[cat.slug].length > 0
                  "
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-6 w-6 mx-10 text-green-600"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M5 13l4 4L19 7"
                  />
                </svg>
              </div>
            </div>
          </div>
        </div>
        <div class="evp-options w-3/4">
          <EVPSection
            v-if="showCategory == 'onboarding'"
            :category="currentCategory[0]"
            @changeValue="selected($event)"
            :values="selectedEVP['onboarding']"
            :checkSelections="checkSelections"
            @preview="showPreview()"
          />
          <EVPSection
            v-if="showCategory == 'compensation'"
            :category="currentCategory[0]"
            @changeValue="selected($event)"
            :values="selectedEVP['compensation']"
            :checkSelections="checkSelections"
            @preview="showPreview()"
          />
          <EVPSection
            v-if="showCategory == 'benefits'"
            :category="currentCategory[0]"
            @changeValue="selected($event)"
            :values="selectedEVP['benefits']"
            :checkSelections="checkSelections"
            @preview="showPreview()"
          />
          <EVPSection
            v-if="showCategory == 'career'"
            :category="currentCategory[0]"
            @changeValue="selected($event)"
            :values="selectedEVP['career']"
            :checkSelections="checkSelections"
            @preview="showPreview()"
          />
          <EVPSection
            v-if="showCategory == 'work_environment'"
            :category="currentCategory[0]"
            @changeValue="selected($event)"
            :values="selectedEVP['work_environment']"
            :checkSelections="checkSelections"
            @preview="showPreview()"
          />
          <EVPSection
            v-if="showCategory == 'culture'"
            :category="currentCategory[0]"
            @changeValue="selected($event)"
            :values="selectedEVP['culture']"
            :checkSelections="checkSelections"
            @preview="showPreview()"
          />
          <EVPSection
            v-if="showCategory == 'organization'"
            :category="currentCategory[0]"
            @changeValue="selected($event)"
            :values="selectedEVP['organization']"
            :checkSelections="checkSelections"
            @preview="showPreview()"
          />
          <EVPSection
            v-if="showCategory == 'offboarding'"
            :category="currentCategory[0]"
            @changeValue="selected($event)"
            :values="selectedEVP['offboarding']"
            :checkSelections="checkSelections"
            @preview="showPreview()"
          />
          <EVPSection
            v-if="showCategory == 'retiral_and_benefits'"
            :category="currentCategory[0]"
            @changeValue="selected($event)"
            :values="selectedEVP['retiral_and_benefits']"
            :checkSelections="checkSelections"
            @preview="showPreview()"
          />
        </div>
      </div>

      <ObkEvpPreview
        :modal="previewModal"
        :onboarding_kit_id="onboarding_kit_id"
        :experience_id="experience_id"
        @closeModal="previewModal.show = false"
        :evpData="previewModal.data"
        :expType="selectedOption.slug"
      ></ObkEvpPreview>
    </div>
  </div>
</template>

<script>
import { apiResource } from "@/common/app.config";
import ApiService from "@/common/api.service";
import EVPSection from "@/components/OnboardingKit/ExpComponents/EVPSection.vue";
import ObkEvpPreview from "@/components/Modals/ObkEvpPreview.vue";
import slugsConfig from "@/common/slugs.config";

export default {
  props: ["expDetails", "experience_id", "onboarding_kit_id"],
  components: { EVPSection, ObkEvpPreview },
  created() {
    this.categories = this.expDetails.exp_data.categories.map((cat) => ({
      name: cat.name,
      slug: cat.slug,
    }));

    this.expData = this.expDetails.exp_data;
    var addedData = this.expDetails.exp_data.added_data;
    if (addedData) {
      var categories = addedData.categories;
      for (var key in this.selectedEVP) {
        if (categories[key]) {
          this.selectedEVP[key] = categories[key];
        }
      }
      this.selectedOption.slug = addedData.option;
    }
    if (this.expDetails.flags.exp_in_kit) {
      this.added = true;
    }
  },
  data() {
    return {
      slugsConfig,
      selectedOption: {
        slug: "",
        name: null,
        type: null,
        preview: null,
        video: null,
        audio: null,
      },
      categories: [],
      showCategory: "onboarding",
      selectedEVP: {
        onboarding: [],
        compensation: [],
        benefits: [],
        career: [],
        work_environment: [],
        culture: [],
        organization: [],
        offboarding: [],
        retiral_and_benefits: [],
      },

      expData: {},
      previewModal: {
        show: false,
        heading: "Employee Value Proposition Preview",
        data: {},
      },
    };
  },
  computed: {
    currentCategory() {
      return this.expData.categories.filter(
        (cat) => cat.slug === this.showCategory
      );
    },
    checkSelections() {
      let total = 0;
      for (let key of Object.keys(this.selectedEVP)) {
        total += this.selectedEVP[key].length;
      }
      let returnValue = false;
      if (total > 0) returnValue = true;

      this.$emit("checkSelections", returnValue);
      this.$emit("expData", {
        option: this.selectedOption.slug,
        data: this.selectedEVP,
      });
      return returnValue;
    },
  },
  methods: {
    selected(e) {
      this.selectedEVP[e.slug] = e.values;
    },
    nextCategory(e) {
      this.showCategory = e;
    },
    gotoCat(category) {
      this.nextCategory(category);
    },
    showPreview() {
      if (this.selectedOption.slug == this.slugsConfig.evpOptions.visual) {
        /* PREPARE DATA FROM EVP DATA */
        ApiService.get(
          apiResource.onboardingKit.experiences.evp.d3PreviewData,
          {
            onboarding_kit_id: this.onboarding_kit_id,
            experience_id: this.experience_id,
            details: JSON.stringify({
              option: this.selectedOption.slug,
              data: this.selectedEVP,
            }),
          }
        )
          .then(({ data }) => {
            this.previewModal.data = data.data.evp_data;
            this.previewModal.show = true;
          })
          .catch(() => {});
      } else {
        this.previewModal.data = this.selectedEVP;
        this.previewModal.show = true;
      }
    },
    assignOption(option) {
      this.selectedOption = option;
    },
    videoPlay(e) {
      let video = e.currentTarget;
      video.play();
    },
    videoPause(e) {
      let video = e.currentTarget;
      video.pause();
    },
  },
};
</script>

<style scoped lang="postcss">
.card {
  @apply bg-nColorFillGray border border-nColorStrokeGray rounded-lg p-4 w-full text-black;
}
</style>
