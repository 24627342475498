<template>
  <div>
    <div class="w-full bg-white border border-nColorStrokeGray rounded-xl p-4">
      <div
        class="w-full bg-nColorFillGray border border-nColorStrokeGray rounded-xl flex mt-2"
        v-for="question in expDetails.exp_data.details.questions"
        :key="question"
      >
        <div class="question w-4/5 p-8 text-black">
          <label for="question" class="text-3xl font-bold mb-2">{{
            removeTags(question.question)
          }}</label>
          <ul class="list-disc ml-8 mt-4">
            <li>Scale from 1 to {{ question.choices.length }}</li>
          </ul>
        </div>
        <div class="text-center w-1/5 p-8">
          <p class="text-md mb-2 text-black">Include this question?</p>
          <Toggle
            onLabel="Yes"
            offLabel="No"
            v-model="toggles[question.slug]"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Toggle from "@vueform/toggle";

export default {
  props: ["expDetails", "experience_id", "onboarding_kit_id"],
  components: { Toggle },
  data() {
    return {
      showPreview: true,
      loading: false,
      errObj: null,
      errorMsg: null,
      successMsg: null,
      added: false,
      toggles: {},
    };
  },
  created() {
    if (this.expDetails.flags.exp_in_kit) {
      this.added = true;
      this.toggles = this.expDetails.exp_data.added_data.toggles;
    } else {
      this.toggles = this.expDetails.exp_data.details.toggles;
    }
  },
  mounted() {},
  methods: {
    updateExpData() {
      if (Object.values(this.toggles).indexOf(true) > -1) {
        this.$emit("expData", JSON.stringify(this.toggles));
      } else {
        this.$emit("expData", null);
      }
    },
    removeTags(str) {
      if (str === null || str === "") return false;
      else str = str.toString();
      return str.replace(/(<([^>]+)>)/gi, "");
    },
  },
  computed: {},
};
</script>
<style scoped lang="postcss"></style>
