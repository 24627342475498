<template>
  <div class="modal welcome-360-preview" v-if="modal.show">
    <!-- Modal -->
    <div class="fixed z-40 inset-0 overflow-y-auto">
      <div
        class="flex items-end justify-center min-h-screen text-center sm:block sm:p-0"
      >
        <div
          @click="closeModal()"
          class="fixed inset-0 transition-opacity"
          aria-hidden="true"
        >
          <div class="absolute inset-0 bg-gray-500 opacity-75"></div>
        </div>

        <!-- This element is to trick the browser into centering the modal contents. -->
        <span
          class="hidden sm:inline-block sm:align-middle sm:h-screen"
          aria-hidden="true"
          >&#8203;</span
        >
        <div
          class="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-6xl sm:w-full"
          role="dialog"
          aria-modal="true"
          aria-labelledby="modal-headline"
          v-if="expLink"
        >
          <div class="bg-blue-900 text-white">
            <div class="sm:flex sm:items-start">
              <div
                class="mt-3 text-center sm:mt-0 sm:text-left relative w-full"
              >
                <div class="flex justify-between">
                  <h2 class="text-3xl font-semibold px-4 pt-4 py-2">
                    Welcome 360 Preview
                  </h2>
                  <button
                    @click="closeModal()"
                    type="button"
                    class="w-1/2 inline-flex justify-end rounded-md px-4 pt-4 py-2 focus:outline-none"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="h-8 w-8 text-white"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M6 18L18 6M6 6l12 12"
                      />
                    </svg>
                  </button>
                </div>
                <div class="mt-2">
                  <div class="welcome-360-wrapper" v-if="expLink">
                    <div class="viewer" data-page="1">
                      <div
                        class="container"
                        tabindex="0"
                        style="user-select: none; -webkit-user-drag: none; touch-action: none;"
                      >
                        <canvas class="view360-canvas"></canvas>
                      </div>
                      <div class="image360_loading">
                        <div class="image360_loadingBar"></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { PanoViewer } from "@egjs/view360";
import { ref, defineComponent, toRefs, reactive } from "vue";

export default defineComponent({
  components: {},
  props: {
    modal: {
      type: Object,
      required: true,
    },
    expLink: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      viewer: null,
      device: null,
    };
  },
  setup() {
    const root = ref();
    const state = reactive({
      fullscreen: false,
      teleport: true,
    });
    return {
      root,
      ...toRefs(state),
    };
  },
  updated() {
    if (this.modal.show && this.expLink) {
      this.setupExperience();
    }
  },
  methods: {
    setupExperience() {
      var self = this;
      this.showLoading(true);
      var container = document.querySelector(".viewer .container");
      var viewer = new PanoViewer(container, {
        image: self.expLink,
        useZoom: false,
        gyroMode: "yawPitch",
        projectionType: "equirectangular",
      })
        .on("ready", function() {
          viewer.lookAt({
            fov: 80,
          });

          setTimeout(function() {
            viewer.lookAt(
              {
                fov: 65,
              },
              500
            );
            self.showLoading(false);
          });
        })
        .on("error", function(e) {
          console.error(e);
        });
      this.viewer = viewer;
    },
    showLoading(isVisible) {
      var loadingEl = document.querySelector(".image360_loading");

      if (!loadingEl) {
        console.warn("loading layer does not exist.");
        return;
      }
      var visible = isVisible == undefined || isVisible == true ? true : false;
      var loadingClassList = loadingEl.classList;

      if (visible) {
        loadingClassList.add("is-loading");
      } else {
        loadingClassList.remove("is-loading");
      }
    },
    closeModal() {
      this.$emit("closeModal", "cancel");
    },
  },
});
</script>
<style scoped>
.viewer {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: none;
  background: rgba(0, 0, 0, 0.7);
}

.viewer:after {
  position: relative;
  display: block;
  content: "";
  padding-top: 60%;
  width: 100%;
  z-index: -1;
}

.viewer .container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.viewer canvas {
  width: 100%;
  height: 100%;
}

.viewer video {
  position: absolute;
  visibility: hidden;
}

.infos {
  display: inline-block;
  text-align: left;
}

p.info .head {
  display: inline-block;
  padding-bottom: 5px;
  font-weight: bold;
}

p.info .value {
  vertical-align: middle;
  font-weight: 300;
  background: #eee;
  padding: 0px 6px;
  font-weight: bold;
  font-size: 14px;
  text-decoration: underline;
}

.button.light {
  border: 1px solid #ddd;
  margin: 5px;
  padding: 10px;
  border-radius: 0;
  font-size: 14px;
}

p.info img {
  width: 100px;
  vertical-align: top;
}

.source {
  position: relative;
  margin: 10px auto;
  width: 100%;
  max-width: 1000px;
  border: 1px solid #ccc;
  background: #f5f5f5;
  box-sizing: border-box;
  padding: 10px;
}

.source pre {
  font-size: 13px;
  text-align: left;
  font-family: Menlo, Monaco, Consolas, "Courier New", monospace;
  word-break: break-all;
  white-space: pre-wrap;
}

.source span,
.source a {
  font-size: 13px;
  margin: 0;
  padding: 0;
  vertical-align: middle;
  font-weight: 300;
  background: #eee;
  padding: 0px 6px;
  font-weight: bold;
  text-decoration: underline;
}

.source pre .comment {
  color: #6a6;
}

.qrcode {
  position: fixed;
  width: 40px;
  height: 40px;
  right: 10px;
  top: 10px;
  border-radius: 50%;
  background: #333;
}
.qrcode:before {
  position: absolute;
  content: "";
  background-size: 100% 100%;
  width: 22px;
  height: 22px;
  margin: 9px;
  top: 0;
  left: 0;
}
.qrviewer {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: #000;
  background: rgba(0, 0, 0, 0.3);
  display: none;
}
.qrviewer img {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  max-width: 100%;
  max-height: 100%;
  min-width: 300px;
  margin: auto;
  z-index: 10;
}

.play-container {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  background: rgba(0, 0, 0, 0.2);
}
.play-container .play {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  box-sizing: border-box;
  background: url("~@/assets/images/360/play.svg") no-repeat;
  border: 0;
  background-size: 100% 100%;
}

header .home {
  margin: 0;
  font-size: 26px;
  background-repeat: no-repeat;
  width: 198px;
  height: 27px;
  position: absolute;
  text-align: left;
}

.home img.egjs_logo {
  width: 34px;
  display: block;
  margin-bottom: -8px;
}

.home a {
  line-height: 40px;
  letter-spacing: -0.8px;
  text-decoration: none;
  display: inline-block;
  color: #2b2b2b;
}
.fullscreen-container {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 100;
}
.enterfs {
  display: block !important;
}
.fullscreen-container .enterfs {
  display: none !important;
}
.exitfs {
  display: none !important;
}
.fullscreen-container .exitfs {
  display: block !important;
}
.entervr {
  display: block !important;
}

.panoviewer-control button {
  background-color: rgba(0, 0, 0, 0.6);
  color: white;
  border-radius: 5px;
  border: none;
  margin: 5px;
  padding: 5px;
  outline: none;
  font-size: 0px;
  margin-bottom: 5px;
}
.panoviewer-control button.enterfs,
.panoviewer-control button.exitfs {
  margin-bottom: 10px;
}
.panoviewer-control button svg {
  width: 30px;
  height: 30px;
}

.panoviewer-control {
  text-align: center;
  position: absolute;
  font-weight: 300;
  top: 0px;
  right: 5px;
  z-index: 1;
}
.panoviewer-control .camera {
  display: inline-block;
  margin: 0;
  padding: 0;
  background-color: rgba(0, 0, 0, 0.6);
  border-radius: 100px;
}
.panoviewer-control .pie,
.camera {
  height: 40px;
  width: 40px;
}

.image360_loading,
.image360_loading:before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
}

.image360_loading {
  display: none;
  width: 92px;
  height: 92px;
  z-index: 1;
}

.image360_loading .image360_loadingBar {
  display: inline-block;
  position: absolute;
  left: 0;
  right: 0;
  top: 64px;
  width: 32px;
  height: 8px;
  margin: auto;
  background-image: url("~@/assets/images/360/common_loading_mo_white.gif");
  background-repeat: no-repeat;
  background-size: 32px 8px;
}

.image360_loading:before {
  background-image: url("~@/assets/images/360/sp_component.png");
  background-repeat: no-repeat;
  -webkit-background-size: 391px 349px;
  background-size: 391px 349px;
  display: inline-block;
  width: 92px;
  height: 92px;
  background-position: 0 -85px;
}

.image360_loading.is-loading {
  display: block;
}

.gyroTouchOptions {
  position: absolute;
  right: 0px;
  bottom: 0px;
  text-align: right;
}

.optionSet .option {
  border: 1px;
  border-radius: 3px;
  background-color: rgb(188, 188, 207);
  color: white;
  padding: 2px;
  height: 34px;
  box-sizing: border-box;
  font-size: 0;
  margin: 5px 0px;
  display: inline-block;
  vertical-align: middle;
}

.optionSet .option:hover {
  background-color: rgb(73, 73, 201);
}

.optionSet .option.selected {
  background-color: rgb(175, 64, 226);
}

.selectGroup {
  position: relative;
  display: inline-block;
  vertical-align: top;
}

.selectGroup .option {
  position: relative;
  border-radius: 5px;
  font-size: 14px;
  line-height: 34px;
  padding: 0px 10px;
  min-width: 34px;
  text-align: center;
}

.statusButton.option {
  position: relative;
  background-color: #7fbeff;
  margin: 5px;
}
.selectGroup .option span {
  position: absolute;
  top: 5px;
  bottom: 5px;
  left: 5px;
  right: 5px;
  background-size: 100% 100%;
}
.selectGroup .option span.vertical {
  transform: rotate(90deg);
}
.statusButton.option svg {
  width: 40px;
  fill: #fff;
  stroke: #fff;
}

.statusButton.option:hover {
  background-color: #7f1e1f;
}

.selectGroup.hide {
  display: none;
}

@media screen and (max-width: 500px) {
  .panoviewer-control {
    right: 0px;
    top: 0px;
  }
  .panoviewer-control button {
    margin: 5px 5px 5px 0px;
    padding: 5px;
    float: right;
  }
  .panoviewer-control button svg {
    width: 20px;
    height: 20px;
  }
  .panoviewer-control .camera {
    transform: scale(0.7);
  }
}
</style>
