<template>
  <div v-if="categories">
    <ActionBar
      ref="expActionBar"
      :expDetails="expDetails"
      :experience_id="experience_id"
      :onboarding_kit_id="onboarding_kit_id"
      :showAddButton="showAddButton"
      :errorMsg="errorMsg"
      :successMsg="successMsg"
      :loading="loading"
      @addExperienceToKit="addExperienceToKit()"
    />
    <div class="bg-white shadow-md border rounded-xl p-4 mb-3 mx-10">
      <Toggles
        ref="expCategoryToggles"
        :categories="categories"
        :expDetails="expDetails"
        :experience_id="experience_id"
        :onboarding_kit_id="onboarding_kit_id"
        @expCategory="updateExpCategory($event)"
        @categoryToggles="updateAllCategories($event)"
      />
      <div id="exp-forms" class="mt-4 px-1">
        <div
          :class="{
            hidden:
              !expDetails.categories.includes(item.id) &&
              !expDetails.exp_data.added_categories.includes(item.id),
          }"
          v-for="item in categories"
          :key="item.id"
          :id="'exp-cat-' + item.id"
        >
          <div class="text-black font-semibold text-lg">{{ item.name }}</div>
          <Form
            :categoryId="item.id"
            :expDetails="expDetails"
            :expFilledDetails="expDetailForFormWithCategoryId(item.id)"
            :experience_id="experience_id"
            :onboarding_kit_id="onboarding_kit_id"
            @expData="expData($event)"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { apiResource } from "@/common/app.config";
import ApiService from "@/common/api.service";
import Form from "@/components/OnboardingKit/Experiences/WelcomeVideo/Form.vue";
import ActionBar from "@/components/OnboardingKit/Experiences/Common/ActionBar.vue";
import Toggles from "@/components/OnboardingKit/Experiences/Common/Toggles.vue";

export default {
  props: ["expDetails", "experience_id", "onboarding_kit_id"],
  components: {
    Form,
    Toggles,
    ActionBar,
  },
  created() {},
  mounted() {
    const vue = this;
    ApiService.get(apiResource.onboardingKit.experienceCategories)
      .then(({ data }) => {
        vue.categories = data.data.categories;
      })
      .catch(() => {});
  },
  data() {
    return {
      added: this.expDetails.flags.exp_in_kit,
      errorMsg: null,
      successMsg: null,
      categories: null,
      loading: false,
    };
  },
  methods: {
    expData(values) {
      const obj = this.categories.filter(
        (cat) => cat.id === values.categoryId
      )[0];
      obj.data = values.details;
    },
    updateExpCategory(data) {
      if (data.checked) {
        var element = document.querySelector("#exp-cat-" + data.id);
        element.classList.remove("hidden");
        document.querySelector("#exp-forms").prepend(element);
      } else {
        document.querySelector("#exp-cat-" + data.id).classList.add("hidden");
      }
    },
    updateAllCategories(categories) {
      this.categoryToggles = categories;
    },
    addExperienceToKit() {
      this.loading = true;
      this.$refs.expCategoryToggles.updateCategoryToggles();
      for (var i = 0; i < this.categoryToggles.length; i++) {
        if (!this.categoryToggles[i].checked) {
          const obj = this.categories.filter(
            (cat) => cat.id === this.categoryToggles[i].id
          )[0];
          obj.data = null;
        }
      }
      this.errorMsg = null;
      var apiRoute = apiResource.onboardingKit.experiences.welcomeVideo.add;
      if (this.added) {
        apiRoute = apiResource.onboardingKit.experiences.welcomeVideo.update;
      }
      ApiService.post(apiRoute, {
        onboarding_kit_id: this.onboarding_kit_id,
        experience_id: this.experience_id,
        templates: this.categories,
      })
        .then(({ data }) => {
          this.successMsg = data.data;
          this.added = true;
          this.$refs.expActionBar.updateSuccessErrorMsgs(
            this.successMsg,
            this.errorMsg
          );
          setTimeout(() => {
            this.goBack();
          }, 2000);
        })
        .catch((error) => {
          this.errorMsg = error.response.data.msg;
          this.loading = false;
        });
    },
    goBack() {
      var backPage = window.history.state.back;
      if (backPage && !backPage.includes("create-journey/catalog/all")) {
        this.$router.back();
      } else {
        this.$router.push({
          name: "CreateOnboardingKitCatalog",
          params: {
            onboarding_kit_id: this.onboarding_kit_id,
            tag: "all",
          },
        });
      }
    },
    expDetailForFormWithCategoryId(categoryId) {
      if (this.expDetails.exp_data[categoryId]) {
        return this.expDetails.exp_data[categoryId];
      } else {
        return null;
      }
    },
  },
  computed: {
    showAddButton() {
      if (this.added) {
        return false;
      }
      if (this.expDetails.flags.exp_in_kit) {
        return false;
      }

      return true;
    },
  },
};
</script>
<style scoped></style>
