<template>
  <div v-if="categories">
    <ActionBar
      ref="expActionBar"
      :expDetails="expDetails"
      :experience_id="experience_id"
      :onboarding_kit_id="onboarding_kit_id"
      :showAddButton="showAddButton"
      :addButtonEnabled="addButtonEnabled"
      :errorMsg="errorMsg"
      :successMsg="successMsg"
      :loading="loading"
      @addExperienceToKit="addExperienceToKit()"
    />
    <div class="bg-white shadow-md border rounded-xl p-4 mb-3 mx-10">
      <Toggles
        ref="expCategoryToggles"
        :categories="categories"
        :expDetails="expDetails"
        :experience_id="experience_id"
        :onboarding_kit_id="onboarding_kit_id"
        @expCategory="updateExpCategory($event)"
        @categoryToggles="updateAllCategories($event)"
      />
      <div id="exp-forms" class="mt-4 px-1">
        <div
          class="bg-nColorFillGray border border-nColorStrokeGray rounded-xl text-bgcolor w-full"
        >
          <div
            id="description"
            class="container"
            v-if="expDetails.exp_data.details"
          >
            <div class="flex items-start rounded-lg">
              <div
                class="w-full md:w-1/2 bg-nColorDarkGray border border-nColorStrokeGray rounded-xl m-4 text-black p-4"
                v-if="expDetails.exp_data.details.description"
              >
                <div>
                  <label class="font-semibold mb-2 text-2xl">
                    {{ expDetails.exp_data.details.description.about.title }}
                  </label>
                  <div class="desc mt-2 ml-4">
                    <ul class="list-disc list-outside">
                      <li
                        v-for="item in expDetails.exp_data.details.description
                          .about.details"
                        :key="item"
                        class="mt-2"
                      >
                        {{ item }}
                      </li>
                    </ul>
                  </div>
                </div>
                <div class="mt-4">
                  <label class="font-semibold text-2xl">
                    {{ expDetails.exp_data.details.description.faq.title }}
                  </label>
                  <div class="desc mt-2 ml-4">
                    <ul class="list-disc list-outside">
                      <li
                        v-for="item in expDetails.exp_data.details.description
                          .faq.details"
                        :key="item"
                        class="mt-2"
                      >
                        <p>{{ item.q }}</p>
                        <p>- {{ item.a }}</p>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div
                class="w-1/2 my-4 mr-4 relative"
                v-if="expDetails.exp_data.details"
              >
                <video
                  controls
                  class="rounded-lg w-full"
                  :src="expDetails.exp_data.details.preview"
                ></video>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { apiResource } from "@/common/app.config";
import ApiService from "@/common/api.service";
import ActionBar from "@/components/OnboardingKit/Experiences/Common/ActionBar.vue";
import Toggles from "@/components/OnboardingKit/Experiences/Common/Toggles.vue";

export default {
  props: ["expDetails", "experience_id", "onboarding_kit_id"],
  components: {
    Toggles,
    ActionBar,
  },
  created() {},
  mounted() {
    const vue = this;
    ApiService.get(apiResource.onboardingKit.experienceCategories)
      .then(({ data }) => {
        vue.categories = data.data.categories;
      })
      .catch(() => {});
  },
  data() {
    return {
      added: this.expDetails.flags.exp_in_kit,
      errorMsg: null,
      successMsg: null,
      categories: null,
      loading: false,
      addButtonEnabled: true,
    };
  },
  methods: {
    // eslint-disable-next-line no-unused-vars
    updateExpCategory(data) {},
    updateAllCategories(categories) {
      this.categoryToggles = categories;
    },
    addExperienceToKit() {
      this.loading = true;
      this.$refs.expCategoryToggles.updateCategoryToggles();
      for (var i = 0; i < this.categoryToggles.length; i++) {
        const obj = this.categories.filter(
          (cat) => cat.id === this.categoryToggles[i].id
        )[0];
        if (!this.categoryToggles[i].checked) {
          obj.data = null;
        } else {
          obj.data = "add";
        }
      }
      this.errorMsg = null;
      var apiRoute = apiResource.onboardingKit.experiences.scavengerHunt.add;
      if (this.added) {
        apiRoute = apiResource.onboardingKit.experiences.scavengerHunt.update;
      }
      ApiService.post(apiRoute, {
        onboarding_kit_id: this.onboarding_kit_id,
        experience_id: this.experience_id,
        categories: this.categories,
      })
        .then(({ data }) => {
          this.successMsg = data.data;
          this.added = true;
          this.$refs.expActionBar.updateSuccessErrorMsgs(
            this.successMsg,
            this.errorMsg
          );
          setTimeout(() => {
            this.goBack();
          }, 2000);
        })
        .catch((error) => {
          this.errorMsg = error.response.data.msg;
          this.loading = false;
        });
    },
    goBack() {
      var backPage = window.history.state.back;
      if (backPage && !backPage.includes("create-journey/catalog/all")) {
        this.$router.back();
      } else {
        this.$router.push({
          name: "CreateOnboardingKitCatalog",
          params: {
            onboarding_kit_id: this.onboarding_kit_id,
            tag: "all",
          },
        });
      }
    },
    expDetailForFormWithCategoryId(categoryId) {
      if (this.expDetails.exp_data[categoryId]) {
        return this.expDetails.exp_data[categoryId];
      } else {
        return null;
      }
    },
  },
  computed: {
    showAddButton() {
      if (this.added) {
        return false;
      }
      if (this.expDetails.flags.exp_in_kit) {
        return false;
      }

      return true;
    },
  },
};
</script>
<style scoped></style>
