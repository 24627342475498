<template>
  <div>
    <div
      class="document-library bg-nColorFillGray border border-nColorStrokeGray rounded-xl w-full"
    >
      <div class="company-details flex items-start rounded-lg">
        <div class="w-1/2 my-4 ml-4">
          <form action="" @submit.prevent="submit()">
            <div
              class="bg-nColorDarkGray border border-nColorStrokeGray rounded-xl text-black p-8"
            >
              <h2 class="text-2xl font-semibold mb-4">
                Add a document to your library
              </h2>
              <div class="title mb-2">
                <label for="title" class="font-semibold mb-2 required-field"
                  >Title</label
                >
                <input
                  class="bg-white rounded-lg text-black px-4 py-3 shadow border border-gray-600 focus:outline-none focus:border-gray-500 w-full"
                  type="text"
                  id="title"
                  name="title"
                  v-model="title"
                />
                <div class="text-sm text-red-500 mt-3 ">
                  <p v-if="v$.title.$invalid && checkValidation">
                    {{ v$.title.$silentErrors[0].$message }}
                  </p>
                  <p v-if="errObj && errObj.title">
                    {{ errObj.title }}
                  </p>
                </div>
              </div>
              <div class="document-upload">
                <label
                  class="font-semibold mb-2 required-field"
                  for="documents"
                >
                  Document file
                </label>

                <FileInput
                  :acceptedFileTypes="['pdf']"
                  ref="document"
                  inputId="documents"
                  inputName="documents"
                  @change="inputFileSelected"
                />
              </div>
            </div>
            <div class="flex place-content-end mt-4">
              <div v-show="errorMsg" class="text-red-500 place-self-end p-3">
                <p class="text-md font-semibold">{{ errorMsg }}</p>
              </div>
              <div>
                <div>
                  <ButtonInput
                    buttonText="Add Document"
                    :loading="loading"
                    :buttonClass="
                      'transition shadow duration-300 ease-out tracking-wider bg-nButtonGreen text-white px-6 py-2 rounded-lg uppercase font-semibold ml-4 outline-none focus:outline-none'
                    "
                    :loadingClass="
                      'cursor-not-allowed transition shadow duration-300 ease-out tracking-wider bg-nButtonGreen text-white px-6 py-2 rounded-lg uppercase font-semibold ml-4 outline-none focus:outline-none'
                    "
                  />
                </div>
              </div>
            </div>
          </form>
        </div>
        <div class="w-1/2 p-4 hidden md:block">
          <div
            class="document-preview p-8 bg-nColorDarkGray border border-nColorStrokeGray rounded-xl"
            v-if="documentLibrary && documentLibrary.length > 0"
          >
            <h2 class="text-2xl font-semibold text-black">
              Preview
            </h2>
            <div class="bookshelf">
              <div class="p-2" v-if="selectedDocumentDetails">
                <div
                  class="shelf-container flex flex-row p-4 bg-bottom bg-no-repeat bg-contain place-content-center relative"
                  v-for="shelf in shelfs"
                  v-tooltip="'Click on thumbnail to preview'"
                  :key="shelf"
                >
                  <div
                    class="mb-10 ml-4 relative"
                    v-for="book in shelf"
                    :key="book"
                  >
                    <button
                      type="button"
                      @click="openModal(selectedDocumentDetails.pdf)"
                      class="hover:scale-110 text-white bg-red-500 rounded-full p-1 transform transition-all
                              absolute -right-2 -top-2 z-10 durarion-300 ease-in-out"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        class="h-5 w-5"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
                        />
                      </svg>
                    </button>
                    <router-link
                      target="_blank"
                      :to="{
                        name: 'DocumentViewer',
                        params: { id: book.document_id },
                      }"
                    >
                      <div
                        class="w-full bg-white rounded-lg shadow-lg overflow-hidden flex flex-col
                                  justify-center items-center hover:scale-105 transform text-gray-700
                                  font-medium hover:font-bold hover:text-blue-700"
                      >
                        <div
                          class="object-center object-cover w-20 h-20 overflow-hidden"
                        >
                          <img
                            class="w-20 h-auto"
                            :src="book.thumbnail"
                            :alt="`Thumbnail for ${book.title}`"
                          />
                        </div>
                        <div class="text-center py-1">
                          <p class="text-sm">{{ book.title }}</p>
                        </div>
                      </div>
                    </router-link>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div v-else class="preview-placeholder">
            <div
              class="bg-nColorBlue h-96 w-full rounded-lg flex justify-center items-center"
            >
              <div class="relative text-white flex flex-col">
                <svg
                  class="w-7 place-self-center"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                  />
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z"
                  />
                </svg>
                <p>
                  Uploaded documents
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <ConfirmationModal
      @remove="deleteDocument(documentToDelete)"
      :modal="modal"
      @closeModal="modal.show = $event"
    />
  </div>
</template>

<script>
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import ApiService from "@/common/api.service";
import { apiResource } from "@/common/app.config";
import "@vueform/multiselect/themes/default.css";
import ConfirmationModal from "@/components/Modals/ConfirmationModal.vue";
import ButtonInput from "@/components/Elements/ButtonInput.vue";
import FileInput from "@/components/Elements/FileInput.vue";

export default {
  props: ["expDetails", "experience_id", "onboarding_kit_id"],
  mounted() {
    ApiService.get(
      apiResource.onboardingKit.experiences.documentLibrary.getDocuments,
      {
        onboarding_kit_id: this.onboarding_kit_id,
        experience_id: this.experience_id,
      }
    )
      .then(({ data }) => {
        this.updateDocumentLibrary(data.data.documents);
        if (this.documentLibrary.length > 0) {
          this.$emit("changeAddButtonStatus", true);
          this.selectedDocument = this.documentLibrary[0].document_id;
        }
      })
      .catch(() => {});
  },
  setup() {
    return { v$: useVuelidate() };
  },
  components: {
    ButtonInput,
    ConfirmationModal,
    FileInput,
  },
  name: "DocumentLibrary",
  data() {
    return {
      title: "",
      showPreview: false,
      chooseFileText: "No file chosen",
      document: null,
      documentLibrary: [],
      documentToDelete: "",
      selectedDocument: "",
      successMsg: null,
      errorMsg: null,
      errObj: null,
      loading: false,
      pageFlip: null,
      checkValidation: false,
      added: false,
      modal: {
        show: false,
        heading: "Remove Document",
        question:
          "Do you really want to remove this document from the Library?",
        action: "remove",
        showLottie: true,
        lottiePath: "lotties/warning.json",
      },
      noOfShelfs: 1,
    };
  },
  validations() {
    return {
      title: { required },
    };
  },
  created() {
    if (this.expDetails.flags.exp_in_kit) {
      this.added = true;
    }
  },
  methods: {
    updateDocumentLibrary(documents) {
      this.documentLibrary = documents;
      if (this.documentLibrary.length > 4) {
        this.noOfShelfs = this.documentLibrary.length / 4;
      }
    },
    inputFileSelected(e) {
      this.document = e.target.files[0];
    },
    deleteDocument(link) {
      ApiService.post(
        apiResource.onboardingKit.experiences.documentLibrary.removeDocument,
        {
          onboarding_kit_id: this.onboarding_kit_id,
          experience_id: this.experience_id,
          document_link: link,
        }
      )
        .then(({ data }) => {
          setTimeout(function() {
            location.reload();
          }, 500);
          return data;
        })
        .catch(() => {});
    },
    submit() {
      this.checkValidation = true;
      if (!this.v$.$invalid) {
        this.loading = true;
        const formData = new FormData();

        formData.append("onboarding_kit_id", this.onboarding_kit_id);
        formData.append("experience_id", this.experience_id);
        formData.append("title", this.title);

        formData.append("document", this.document, this.document.name);
        ApiService.post(
          apiResource.onboardingKit.experiences.documentLibrary.addDocument,
          formData
        )
          .then(({ data }) => {
            this.updateDocumentLibrary(data.data.documents);
            this.title = "";
            this.selectedDocument = this.documentLibrary[0].document_id;
            this.$refs.document.resetComponent();
            this.errorMsg = null;
            this.errObj = null;
            this.checkValidation = false;
            this.created = true;
            this.$emit("changeAddButtonStatus", true);
          })
          .catch((error) => {
            this.successMsg = null;
            if (typeof error.response.data.msg == "object") {
              this.errObj = error.response.data.msg;
            } else {
              this.errorMsg = error.response.data.msg;
            }
          })
          .finally(() => {
            this.loading = false;
          });
      }
    },
    openModal(link) {
      this.documentToDelete = link;
      this.modal.show = true;
    },
  },
  computed: {
    docNames() {
      if (this.documentLibrary.length > 0) {
        return this.documentLibrary.map((exp) => ({
          value: exp.document_id,
          label: exp.title,
        }));
      }
      return [];
    },
    shelfs() {
      let shelfs = [];
      let mid = Math.ceil(this.documentLibrary.length / this.noOfShelfs);
      for (let shelf = 0; shelf < this.noOfShelfs; shelf++) {
        shelfs.push(this.documentLibrary.slice(shelf * mid, shelf * mid + mid));
      }
      return shelfs;
    },
    selectedDocumentDetails() {
      if (this.documentLibrary.length > 0) {
        const document = this.documentLibrary.filter(
          (doc) => doc.document_id === this.selectedDocument
        )[0];

        return document;
      }
      return null;
    },
  },
};
</script>
<style scoped>
.shelf-container {
  background-image: url("~@/assets/images/document-library/shelf_metal.png");
}
</style>
