<template>
  <div class="evp-wrapper">
    <div class="flex justify-around gap-20 pl-20 pt-20">
      <div class="w-1/2">
        <div class="circular-menu">
          <div class="menu-circle">
            <a
              v-for="category in computedEVPData"
              :key="category.slug"
              :id="category.slug"
              @click.prevent="activateCategory(category)"
              class="hidden"
            >
              <img
                :src="require('@/assets/' + category.icon)"
                class="w-10 ml-4 mt-4"
              />
            </a>
          </div>
          <div
            class="centerContent font-semibold text-xl"
            v-if="selectedCategory.icon"
          >
            <img
              :src="require('@/assets/' + selectedCategory.icon)"
              class="w-12"
            />
            <p class="w-full px-3 text-lg" v-if="selectedCategory.title">
              {{ selectedCategory.title }}
            </p>
          </div>
          <div class="centerContent font-semibold text-5xl pt-14" v-else>
            <p>EVP</p>
          </div>
        </div>
      </div>
      <div id="contentText" class="w-1/2 hidden">
        <div class="text-white mr-20">
          <div
            v-if="selectedCategory.title"
            class="bg-black bg-opacity-40 px-20 py-6 text-center rounded-lg contentTitleCard relative"
          >
            <div v-if="selectedCategory.icon" class="selectedIcon ml-20">
              <img
                :src="require('@/assets/' + selectedCategory.icon)"
                class="w-10 ml-4 mt-4"
              />
            </div>
            <p class="w-full mt-4 font-bold text-2xl">
              {{ selectedCategory.title }}
            </p>
          </div>
          <div v-if="selectedCategory.values">
            <div
              class="bg-black bg-opacity-40 px-20 py-1 text-center rounded-lg contentItemCard my-1 text-base"
              v-for="item in selectedCategory.values"
              :key="item"
            >
              {{ item }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import slugsConfig from "@/common/slugs.config";
import "animate.css";

export default {
  name: "ObkEvpBubbles",
  components: {},
  props: {
    evpData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      slugsConfig,
      selectedCategory: {
        title: null,
        icon: null,
        values: null,
      },
    };
  },
  computed: {
    computedEVPData() {
      const categories = [];
      for (var key of Object.keys(this.evpData)) {
        if (this.evpData[key].length != 0) {
          categories.push({
            slug: key,
            label: this.slugsConfig.evpSlugs[key].label,
            icon: this.slugsConfig.evpSlugs[key].icon,
            values: this.evpData[key],
          });
        }
      }
      return categories;
    },
  },
  mounted() {
    var menuCircle = document.querySelector(".menu-circle");
    setTimeout(() => {
      if (menuCircle) {
        document.querySelector(".menu-circle").classList.add("open");
      }
    }, 500);
    var items = document.querySelectorAll(".menu-circle a");

    for (var i = 0, l = items.length; i < l; i++) {
      if (items[i].classList.contains("hidden")) {
        this.positionCircleElement(items, i, l);
      }
    }
  },
  methods: {
    positionCircleElement(items, i, l) {
      setTimeout(() => {
        items[i].style.left =
          (
            50 -
            35 * Math.cos(-0.5 * Math.PI - 2 * (1 / l) * i * Math.PI)
          ).toFixed(4) + "%";
        items[i].style.top =
          (
            50 +
            35 * Math.sin(-0.5 * Math.PI - 2 * (1 / l) * i * Math.PI)
          ).toFixed(4) + "%";
        items[i].classList.remove("hidden");
      }, (i + 1) * 200);
    },
    activateCategory(category) {
      this.selectedCategory.title = category.label;
      this.selectedCategory.icon = category.icon;
      this.selectedCategory.values = category.values;
      this.selectCategoryAndHighlight(category);
      var contentText = document.querySelector("#contentText");
      if (contentText.classList.contains("hidden")) {
        contentText.classList.remove("hidden");
        contentText.classList.add("animate__animated", "animate__fadeInRight");
        document
          .querySelector(".circular-menu")
          .classList.add("animate__animated", "animate__fadeInRight");
      } else {
        contentText.classList.add(
          "animate__animated",
          "animate__fast",
          "animate__zoomIn"
        );
      }
      contentText.addEventListener("animationend", () => {
        contentText.classList.remove(
          "animate__animated",
          "animate__fast",
          "animate__fadeInRight",
          "animate__zoomIn"
        );
        // document.querySelector(".menu-circle").classList.remove("rotateDial");
      });
      //   document.querySelector(".menu-circle").style.webkitTransform =
      //     "rotate(90deg)";
    },
    selectCategoryAndHighlight(category) {
      for (var key of Object.keys(this.evpData)) {
        if (this.evpData[key].length != 0) {
          if (key == category.slug) {
            document.querySelector("#" + key).classList.add("categoryActive");
          } else {
            document
              .querySelector("#" + key)
              .classList.remove("categoryActive");
          }
        }
      }
    },
  },
};
</script>

<style scoped>
.evp-wrapper {
  height: 600px;
  background-image: url("~@/assets/images/evp/backgrounds/1.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
.circular-menu {
  width: 400px;
  height: 400px;
  position: relative;
}

.menu-circle {
  width: 400px;
  height: 400px;
  opacity: 0;
  -webkit-transform: scale(0);
  -moz-transform: scale(0);
  transform: scale(0);
  -webkit-transition: all 0.4s ease-out;
  -moz-transition: all 0.4s ease-out;
  transition: all 0.4s ease-out;
}

.open.menu-circle {
  opacity: 1;
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  transform: scale(1);
}

.menu-circle a {
  text-decoration: none;
  background: #7c7c7c;
  border-radius: 50%;
  height: 70px;
  width: 70px;
  margin-left: -40px;
  margin-top: -40px;
  position: absolute;
  text-align: center;
  cursor: pointer;
  z-index: 999;
}

.menu-circle a:hover {
  transform: scale(1.2);
}
.categoryActive {
  transform: scale(1.2);
  background: #7c7c7c;
}
.centerContent {
  position: absolute;
  top: calc(50% - 80px);
  left: calc(50% - 80px);
  text-align: center;
  color: #2e2e2e;
  display: block;
  width: 160px;
  height: 160px;
  border-radius: 50%;
  background: #c1c1c1;
  z-index: 999;
}
.centerContent img {
  margin-left: calc(50% - 25px);
  margin-top: 30px;
}
.centerContent p {
  margin-top: 5px;
}
.show-content {
  display: block;
}
.slide-left {
  display: block;
  animation: 1s slide-left;
}
@keyframes slide-left {
  from {
    margin-right: 0%;
  }
  to {
    margin-right: 5%;
  }
}
.selectedIcon {
  text-decoration: none;
  background: #7c7c7c;
  border-radius: 50%;
  height: 70px;
  width: 70px;
  top: -35px;
  position: absolute;
  text-align: center;
  z-index: 999;
}
.contentTitleCard {
  border: 4px solid #00a96b;
}
.contentItemCard {
  border: 2px solid #7014f2;
}
.rotateDial {
  transform: rotate(45deg);
}
</style>
