<template>
  <div class="modal evp-preview" v-if="modal.show">
    <!-- Modal -->
    <div class="fixed z-40 inset-0 overflow-y-auto">
      <div
        class="flex items-end justify-center min-h-screen text-center sm:block sm:p-0"
      >
        <div
          @click="closeModal()"
          class="fixed inset-0 transition-opacity"
          aria-hidden="true"
        >
          <div class="absolute inset-0 bg-gray-500 opacity-75"></div>
        </div>

        <!-- This element is to trick the browser into centering the modal contents. -->
        <span
          class="hidden sm:inline-block sm:align-middle sm:h-screen"
          aria-hidden="true"
          >&#8203;</span
        >
        <div
          class="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-6xl sm:w-full"
          role="dialog"
          aria-modal="true"
          aria-labelledby="modal-headline"
        >
          <div class="bg-blue-900 text-white">
            <div class="sm:flex sm:items-start">
              <div
                class="mt-3 text-center sm:mt-0 sm:text-left relative w-full"
              >
                <div class="flex justify-between">
                  <h2 class="text-3xl font-semibold px-4 pt-4 py-2">
                    Employee Value Proposition Preview
                  </h2>
                  <button
                    @click="closeModal()"
                    type="button"
                    class="w-1/2 inline-flex justify-end rounded-md px-4 pt-4 py-2 focus:outline-none"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="h-8 w-8 text-white"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M6 18L18 6M6 6l12 12"
                      />
                    </svg>
                  </button>
                </div>
                <div class="evp-wrapper bg-white mt-2">
                  <Radial
                    :evpData="evpData"
                    v-if="experienceType == slugsConfig.evpOptions.radial"
                  />
                  <Visual
                    :evpData="evpData"
                    v-else-if="experienceType == slugsConfig.evpOptions.visual"
                  />
                  <Bubbles
                    :evpData="evpData"
                    v-if="experienceType == slugsConfig.evpOptions.bubbles"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import slugsConfig from "@/common/slugs.config";
import Radial from "@/components/OnboardingKit/Experiences/EVP/Previews/Radial.vue";
import Visual from "@/components/OnboardingKit/Experiences/EVP/Previews/Visual.vue";
import Bubbles from "@/components/OnboardingKit/Experiences/EVP/Previews/Bubbles.vue";
import "animate.css";

export default {
  name: "ObkEvpPreview",
  components: { Radial, Visual, Bubbles },
  props: {
    modal: {
      type: Object,
      required: true,
    },
    evpData: {
      type: Object,
      required: true,
    },
    onboarding_kit_id: {
      type: String,
      required: true,
    },
    experience_id: {
      type: String,
      required: true,
    },
    expType: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      slugsConfig,
      selectedCategory: {
        title: null,
        icon: null,
        centericon: null,
        values: null,
      },
      experienceType: this.expType,
    };
  },
  computed: {},
  methods: {
    closeModal() {
      this.$emit("closeModal", "cancel");
    },
    actionType(action) {
      this.$emit("closeModal", action);
      this.$emit(action);
    },
  },
  watch: {
    expType(newexpType, oldexpType) {
      if (oldexpType != newexpType) {
        this.experienceType = this.expType;
      }
    },
  },
};
</script>

<style scoped>
.evp-wrapper {
  height: 600px;
}
</style>
