<template>
  <div v-if="categories">
    <ActionBar
      ref="expActionBar"
      :expDetails="expDetails"
      :experience_id="experience_id"
      :onboarding_kit_id="onboarding_kit_id"
      :showAddButton="showAddButton"
      :addButtonEnabled="addButtonEnabled"
      :errorMsg="errorMsg"
      :successMsg="successMsg"
      :loading="loading"
      @addExperienceToKit="addExperienceToKit()"
    />
    <div class="bg-white shadow-md border rounded-xl p-4 mb-3 mx-10">
      <Toggles
        ref="expCategoryToggles"
        :categories="categories"
        :expDetails="expDetails"
        :experience_id="experience_id"
        :onboarding_kit_id="onboarding_kit_id"
        @expCategory="updateExpCategory($event)"
        @categoryToggles="updateAllCategories($event)"
      />
      <div id="exp-forms" class="mt-4 px-1">
        <div>
          <Form
            ref="expForm"
            :expDetails="expDetails"
            :experience_id="experience_id"
            :onboarding_kit_id="onboarding_kit_id"
            @checkSelections="addButtonEnabled = $event"
            @expData="expData($event)"
            @changeAddButtonStatus="changeAddButtonStatus($event)"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { apiResource } from "@/common/app.config";
import ApiService from "@/common/api.service";
import Form from "@/components/OnboardingKit/Experiences/Pulse1Week/Form.vue";
import ActionBar from "@/components/OnboardingKit/Experiences/Common/ActionBar.vue";
import Toggles from "@/components/OnboardingKit/Experiences/Common/Toggles.vue";

export default {
  props: ["expDetails", "experience_id", "onboarding_kit_id"],
  components: {
    Form,
    Toggles,
    ActionBar,
  },
  created() {},
  mounted() {
    const vue = this;
    ApiService.get(apiResource.onboardingKit.experienceCategories)
      .then(({ data }) => {
        vue.categories = data.data.categories;
      })
      .catch(() => {});
  },
  data() {
    return {
      added: this.expDetails.flags.exp_in_kit,
      errorMsg: null,
      successMsg: null,
      categories: null,
      loading: false,
      addButtonEnabled: true,
      canAdd: false,
    };
  },
  methods: {
    expData(details) {
      if (details) {
        this.canAdd = true;
        for (var i = 0; i < this.categories.length; i++) {
          this.categories[i]["data"] = details;
        }
      }
    },
    // eslint-disable-next-line no-unused-vars
    updateExpCategory(data) {},
    updateAllCategories(categories) {
      this.categoryToggles = categories;
    },
    addExperienceToKit() {
      this.$refs.expForm.updateExpData();
      this.$refs.expCategoryToggles.updateCategoryToggles();
      for (var i = 0; i < this.categoryToggles.length; i++) {
        if (!this.categoryToggles[i].checked) {
          const obj = this.categories.filter(
            (cat) => cat.id === this.categoryToggles[i].id
          )[0];
          obj.data = null;
        }
      }
      if (this.canAdd) {
        this.loading = true;
        this.errorMsg = null;
        var apiRoute = apiResource.onboardingKit.experiences.pulse1Week.add;
        if (this.added) {
          apiRoute = apiResource.onboardingKit.experiences.pulse1Week.update;
        }
        let params = {
          onboarding_kit_id: this.onboarding_kit_id,
          experience_id: this.experience_id,
          categories: this.categories,
        };
        ApiService.post(apiRoute, params)
          .then(({ data }) => {
            this.successMsg = data.data;
            this.added = true;
            this.$refs.expActionBar.updateSuccessErrorMsgs(
              this.successMsg,
              this.errorMsg
            );
            setTimeout(() => {
              this.goBack();
            }, 2000);
          })
          .catch((error) => {
            this.errorMsg = error.response.data.msg;
            this.loading = false;
          });
      }
    },
    goBack() {
      var backPage = window.history.state.back;
      if (backPage && !backPage.includes("create-journey/catalog/all")) {
        this.$router.back();
      } else {
        this.$router.push({
          name: "CreateOnboardingKitCatalog",
          params: {
            onboarding_kit_id: this.onboarding_kit_id,
            tag: "all",
          },
        });
      }
    },
    expDetailForFormWithCategoryId(categoryId) {
      if (this.expDetails.exp_data[categoryId]) {
        return this.expDetails.exp_data[categoryId];
      } else {
        return null;
      }
    },
    changeAddButtonStatus(status) {
      this.$refs.expActionBar.updateEnableAddButton(status);
    },
  },
  computed: {
    showAddButton() {
      if (this.added) {
        return false;
      }
      if (this.expDetails.flags.exp_in_kit) {
        return false;
      }

      return true;
    },
  },
};
</script>
<style scoped></style>
