<template>
  <div class="text-black">
    <div class="mt-2 card">
      <h2 class="text-2xl font-semibold">
        Hover on a thumbnail to preview and click to select an option.
      </h2>
      <div class="flex gap-8" v-if="expDetails && expDetails.exp_data">
        <div
          class="option-box"
          v-for="option in expDetails.exp_data.options"
          :key="option.slug"
        >
          <div class="selector">
            <input
              class="w-4 h-4 absolute opacity-0"
              type="radio"
              name="option"
              :id="'option_' + option.slug"
              :value="option.slug"
              required
              v-model="selectedOption"
            />
            <label
              @click.prevent="assignOption(option)"
              class="ml-3 text-lg font-semibold cursor-pointer"
              :for="'option_' + option.slug"
            >
              <div class="w-60 relative">
                <div
                  v-show="selectedOption.slug === option.slug"
                  class="absolute -top-4 -right-4 text-green-600 bg-white cursor-default rounded-full"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="currentColor"
                    class="w-8 bi bi-x-circle-fill"
                    viewBox="0 0 16 16"
                  >
                    <path
                      d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z"
                    />
                  </svg>
                </div>
                <video
                  loop
                  @mouseenter="videoPlay($event)"
                  @mouseleave="videoPause($event)"
                  :class="{
                    'border-green-600': selectedOption.slug === option.slug,
                  }"
                  class="w-full rounded-lg border-4 border-gray-500"
                  :src="option.preview"
                  muted
                ></video>
              </div>
              <span class="inline-block mt-2">{{ option.name }}</span>
            </label>
          </div>
        </div>
      </div>
    </div>
    <div class="mt-2 card">
      <h2 class="text-2xl font-semibold">Upload images</h2>
      <div class="mt-2 flex gap-8">
        <div>
          <div
            class="h-64 w-40 border-2 border-nColorBlue bg-blue-900 grid justify-center content-center overflow-hidden"
          >
            <div class="font-BukhariScript text-white">
              <div class="text-center flex flex-col mt-4">
                <span class="text-3xl">Hi</span>
                <span class="text-2xl">FirstName</span>
              </div>
            </div>
            <div class="font-Rajdhani text-white">
              <div class="text-center flex flex-col mt-4">
                <span class="text-3xl">Welcome</span>
                <span class="text-2xl">to</span>
              </div>
            </div>
            <div
              class="w-16 h-16 ml-6 bg-white flex items-center justify-center"
            >
              <p>Logo</p>
            </div>
          </div>
          <div class="bg-gray-300 px-4 py-2 text-center font-semibold mt-2">
            01
          </div>
        </div>
        <div
          v-for="(value, name, index) in images"
          :key="index"
          class="relative mt-4 ml-2"
        >
          <div
            :class="{
              'h-30 w-60': images[name].orientation === 'landscape',
              'h-52 w-30': images[name].orientation === 'portrait',
              'border-gray-300': !images[name].src,
              'border-blue-300': images[name].src,
            }"
            class="border-2 grid justify-center overflow-hidden"
          >
            <div v-if="images[name].src">
              <img
                class="w-full h-full object-contain"
                :src="images[name].src"
                alt=""
                :ref="name + 'Src'"
              />
            </div>
            <p class="text-6xl place-self-center text-center p-2" v-else>
              <span class="font-semibold text-gray-700">{{
                images[name].value
              }}</span>
              <span
                class="block text-sm text-gray-400"
                v-if="images[name].orientation === 'landscape'"
              >
                Aspect Ratio: 16:9<br />Resolution: 1920x1080
              </span>
              <span class="block text-sm text-gray-400" v-else>
                Aspect Ratio: 9:16<br />Resolution: 1080x1920
              </span>
            </p>
          </div>
          <div class="mt-2">
            <div v-if="images[name].src">
              <div class="bg-blue-300 px-4 py-2 text-center font-semibold">
                <p v-if="images[name].existing">
                  {{ images[name].value }}
                </p>
                <p v-else>
                  {{ images[name].src.name }}
                </p>
              </div>
            </div>
            <div v-else>
              <FileInput
                :acceptedFileTypes="['png', 'jpg', 'jpeg']"
                :ref="name"
                :inputId="name"
                :inputName="name"
                :chooseFileText="null"
                buttonText="Select Image"
                @change="setImage(name, name + 'Src', $event)"
              />
            </div>
          </div>
          <button
            class="text-nColorRed absolute -top-4 -right-4 bg-white rounded-full"
            @click.prevent="unSetImage(name, name + 'Src')"
            v-if="images[name].src"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="currentColor"
              class="w-8 bi bi-x-circle-fill"
              viewBox="0 0 16 16"
            >
              <path
                d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293 5.354 4.646z"
              />
            </svg>
          </button>
        </div>
      </div>
    </div>
    <div class="mt-4 flex justify-end">
      <div v-show="errorMsg" class="text-nColorRed place-self-end p-3">
        <p class="text-md font-semibold">{{ errorMsg }}</p>
      </div>
      <ButtonInput
        @click.prevent="showPreview()"
        buttonText="Preview"
        :loading="loading"
        :buttonClass="'transition shadow duration-300 ease-out tracking-wider bg-nColorBlue text-white px-6 py-2 rounded-lg uppercase font-semibold ml-4 outline-none focus:outline-none'"
        :loadingClass="'cursor-not-allowed transition shadow duration-300 ease-out tracking-wider bg-nColorBlue text-white px-6 py-2 rounded-lg uppercase font-semibold ml-4 outline-none focus:outline-none'"
        v-if="checkSelections"
      />
      <div
        class="transition shadow duration-300 ease-out tracking-wider pointer-events-none bg-gray-400 text-white px-6 py-2 rounded-lg uppercase font-semibold ml-4 outline-none focus:outline-none"
        v-else
      >
        Preview
      </div>
    </div>
    <Welcome360Preview
      :modal="modal"
      @closeModal="modal.show = false"
      :expLink="expLink"
    />
  </div>
</template>

<script>
import ApiService from "@/common/api.service";
import { apiResource } from "@/common/app.config";
import Welcome360Preview from "@/components/Modals/ObkWelcome360Preview.vue";
import FileInput from "@/components/Elements/CompactFileInput.vue";
import ButtonInput from "@/components/Elements/ButtonInput.vue";

export default {
  props: ["expDetails", "experience_id", "onboarding_kit_id"],
  components: { Welcome360Preview, FileInput, ButtonInput },
  created() {
    if (
      this.expDetails.exp_data.added_data &&
      this.expDetails.exp_data.added_data.base_pano.option
    ) {
      this.selectedOption.slug =
        this.expDetails.exp_data.added_data.base_pano.option;
    }
    if (
      this.expDetails.exp_data.added_data &&
      this.expDetails.exp_data.added_data.image_paths
    ) {
      let imagePaths = this.expDetails.exp_data.added_data.image_paths;
      for (var i = 1; i < imagePaths.length; i++) {
        var y = i + 1;
        this.images["input" + y].src = imagePaths[i].url;
        this.images["input" + y].existing = true;
      }
    }
  },
  data() {
    return {
      selectedOption: {
        slug: null,
        name: null,
        type: null,
        preview: null,
        video: null,
        audio: null,
      },
      expData: {},
      expLink: "",
      loading: false,
      errorMsg: null,
      modal: {
        show: false,
        heading: "Welcome 360 Preview",
      },
      images: {
        input2: {
          src: null,
          value: "02",
          orientation: "landscape",
          existing: false,
        },
        input3: {
          src: null,
          value: "03",
          orientation: "portrait",
          existing: false,
        },
        input4: {
          src: null,
          value: "04",
          orientation: "landscape",
          existing: false,
        },
        input5: {
          src: null,
          value: "05",
          orientation: "landscape",
          existing: false,
        },
      },
    };
  },
  computed: {
    checkSelections() {
      let imagesFlag = true;
      let returnValue = false;
      for (let key of Object.keys(this.images)) {
        if (!this.images[key].src) {
          imagesFlag = false;
        }
      }
      if (imagesFlag && this.selectedOption.slug) {
        returnValue = true;
      }
      this.$emit("checkSelections", returnValue);
      this.$emit("expData", this.getFormData());
      return returnValue;
    },
  },
  methods: {
    assignOption(option) {
      this.selectedOption = option;
    },
    videoPlay(e) {
      let video = e.currentTarget;
      video.play();
    },
    videoPause(e) {
      let video = e.currentTarget;
      video.pause();
    },
    showPreview() {
      this.loading = true;
      const formData = this.getFormData();
      ApiService.post(
        apiResource.onboardingKit.experiences.welcome360.preview,
        formData
      )
        .then(({ data }) => {
          this.expLink = data.data.url;
          this.modal.show = true;
        })
        .catch((error) => {
          this.errorMsg = error.response.data.msg;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    getFormData() {
      const formData = new FormData();

      for (let key of Object.keys(this.images)) {
        formData.append(key, this.images[key].src);
      }

      formData.append("onboarding_kit_id", this.onboarding_kit_id);
      formData.append("experience_id", this.experience_id);
      formData.append("option", this.selectedOption.slug);
      formData.append("images", JSON.stringify(this.images));
      return formData;
    },
    setImage(input, inputSrc, event) {
      this.images[input].src = event.target.files[0];
      let vue = this;
      var reader = new FileReader();
      reader.onload = function (e) {
        vue.$refs[inputSrc][0].src = e.target.result;
      };
      reader.readAsDataURL(event.target.files[0]);
    },
    unSetImage(input, inputSrc) {
      this.images[input].src = null;
      this.images[input].existing = false;
      this.$refs[inputSrc][0].src = null;
    },
  },
};
</script>

<style scoped lang="postcss">
.card {
  @apply bg-nColorFillGray border border-nColorStrokeGray rounded-lg p-4 w-full text-black;
}
</style>
