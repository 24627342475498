<template>
  <div id="wizard-step-script" v-show="showIndex == 'script'">
    <div class="welcome-video text-black w-full">
      <div class="welcome-video-container pb-3">
        <div class="flex flex-col md:flex-row items-start rounded-lg gap-3">
          <div
            class="bg-white border border-nColorStrokeGray rounded-xl p-4 w-1/2"
            v-if="samplePreview"
          >
            <div class="model-viewer-container" v-if="currentPreview">
              <p class="text-2xl font-semibold">STEP 1</p>
              <p class="text-sm font-medium mb-4">
                Select an avatar below to see a sample preview
              </p>
              <VideoPlayer
                :identifier="'avatarPreview'"
                :src="currentPreview.video"
                :poster="currentPreview.thumbnail"
              />
              <p class="mt-1 pl-1 text-lg font-semibold">
                {{ samplePreview.actor_name }}
              </p>
            </div>
            <div class="mt-4">
              <label class="font-semibold mb-2 text-2xl"
                >Choose your avatar</label
              >
              <Carousel
                :ref="'carousel' + categoryId"
                :items-to-show="2.5"
                :wrap-around="false"
                :items-to-scroll="2"
                v-model="currentSlide"
                class="px-4"
              >
                <Slide
                  v-for="avatar in expDetails.exp_data.avatars"
                  :key="avatar.actor_id"
                >
                  <div class="carousel__item w-full px-2">
                    <a
                      @click.prevent="selectAvatar(avatar)"
                      class="cursor-pointer"
                    >
                      <img
                        :src="avatar.thumbnail"
                        class="rounded-lg"
                        :class="{
                          'border-4 border-yellow-400':
                            samplePreview.actor_id == avatar.actor_id,
                          border: samplePreview.actor_id != avatar.actor_id,
                        }"
                      />
                      <p class="text-sm text-gray-600 pl-1 pt-1">
                        {{ avatar.actor_name }}
                      </p>
                    </a>
                  </div>
                </Slide>

                <template #addons>
                  <Navigation />
                </template>
              </Carousel>
            </div>
          </div>
          <div class="w-full md:w-1/2 text-bgcolor">
            <div
              class="bg-white border border-nColorStrokeGray rounded-xl p-4 welcome-script"
            >
              <div>
                <p class="text-2xl font-semibold">STEP 2</p>
                <p class="text-sm font-medium">
                  Select the language based on your region.
                </p>
                <div class="mt-3">
                  <Multiselect
                    @select="updatePreview"
                    v-model="selectedLanguage"
                    placeholder="Select Language"
                    ref="avatarLanguage"
                    :canClear="false"
                    :caret="true"
                    class="multiselect-box"
                    :options="
                      languages.map((lang) => ({
                        value: lang.value,
                        label: lang.name,
                        image: lang.image,
                      }))
                    "
                  >
                    <template v-slot:singlelabel="{ value }">
                      <div class="multiselect-single-label">
                        <img class="character-label-icon" :src="value.image" />
                        <p class="pl-2">{{ value.label }}</p>
                      </div>
                    </template>
                    <template v-slot:option="{ option }">
                      <img class="character-option-icon" :src="option.image" />
                      <p class="pl-2">{{ option.label }}</p>
                    </template>
                  </Multiselect>
                </div>
              </div>
            </div>
            <div
              class="bg-white border border-nColorStrokeGray rounded-xl p-4 welcome-script mt-3"
            >
              <div>
                <p class="text-2xl font-semibold">STEP 3</p>
                <p class="text-sm font-medium">
                  Edit the text in the box below. This text will be voiced out
                  by the avatar you have chosen.
                </p>
                <textarea
                  :maxlength="scriptLength"
                  minlength="25"
                  class="textInput w-full mt-4 px-4 py-2 font-medium text-justify rounded-md border"
                  style="resize:none"
                  cols="32"
                  rows="6"
                  name="welcomeScript"
                  id="welcomeScript"
                  v-model="welcomeScript"
                  @change="updateExpData"
                >
                </textarea>
                <span
                  :class="{
                    'text-gray-600': welcomeScript.length < scriptLength - 10,
                    'text-red-500': welcomeScript.length >= scriptLength - 10,
                  }"
                  class="float-left text-xs md:text-base"
                >
                  {{ scriptLength - welcomeScript.length }}/{{ scriptLength }}
                  characters
                </span>
                <div class="mt-12">
                  Use the following parameters within your text to personalize
                  it for every candidate:
                  <ul class="list-disc list-inside">
                    <li>&lt;first_name&gt;</li>
                    <li>&lt;date_of_joining&gt;</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VideoPlayer from "@/components/Elements/VideoPlayer.vue";
import { Carousel, Navigation, Slide } from "vue3-carousel";
import "vue3-carousel/dist/carousel.css";
import Multiselect from "@vueform/multiselect";

export default {
  props: [
    "categoryId",
    "expDetails",
    "expFilledDetails",
    "experience_id",
    "onboarding_kit_id",
  ],
  components: {
    Carousel,
    Navigation,
    Slide,
    Multiselect,
    VideoPlayer,
  },
  created() {
    if (this.expDetails.exp_data) {
      this.welcomeScript = this.expFilledDetails
        ? this.expFilledDetails.default_script
        : this.expDetails.exp_data.default_script;
      var languageId = this.expDetails.exp_data.language_id;
      for (let i = 0; i < this.expDetails.exp_data.avatars.length; i++) {
        if (
          this.expFilledDetails &&
          this.expFilledDetails.selected_avatar &&
          this.expFilledDetails.selected_avatar.actor_id ==
            this.expDetails.exp_data.avatars[i].actor_id
        ) {
          this.initialSlide = i;
          this.samplePreview = this.expFilledDetails.selected_avatar;
          if (this.expFilledDetails && this.expFilledDetails.language_id) {
            this.selectedLanguage = this.expFilledDetails.language_id;
            languageId = this.expFilledDetails.language_id;
          }
          break;
        } else if (
          !this.expFilledDetails &&
          this.expDetails.exp_data.avatars[i].is_default
        ) {
          this.samplePreview = this.expDetails.exp_data.avatars[i];
          break;
        }
      }
      this.allLanguages = this.expDetails.exp_data.languages;
      this.setLanguageBasedOnGender(languageId);
      this.metaTemplates = this.expDetails.exp_data.templates;
      this.updatePreview();
    }
    if (this.expDetails.flags.exp_in_kit) {
      this.added = true;
    }
  },
  mounted() {
    this.currentSlide = this.initialSlide;
  },
  data() {
    return {
      welcomeScript: null,
      scriptLength: 240,
      samplePreview: null,
      languages: null,
      allLanguages: null,
      selectedLanguage: null,
      currentPreview: null,
      selectedTemplateId: null,
      metaTemplates: null,
      initialSlide: 0,
      currentSlide: 0,
      showIndex: "script",
      wizardSteps: ["Script", "Preview"],
    };
  },
  methods: {
    updateExpData() {
      this.$emit("expData", {
        categoryId: this.categoryId,
        details: {
          actor_details: this.samplePreview,
          language_id: this.selectedLanguage,
          template_id: this.selectedTemplateId,
          script: this.welcomeScript,
        },
      });
    },
    selectAvatar(avatar) {
      this.samplePreview = avatar;
      this.setLanguageBasedOnGender(null);
      this.updatePreview();
    },
    updatePreview() {
      let selectedTemplate = this.metaTemplates.find(
        (o) =>
          o.actor_id === this.samplePreview.actor_id &&
          o.language_id === this.selectedLanguage
      );
      this.currentPreview = {
        video: selectedTemplate.preview,
        thumbnail: this.samplePreview.thumbnail,
      };
      this.selectedTemplateId = selectedTemplate.template_id;
      this.updateExpData();
    },
    setLanguageBasedOnGender(languageId) {
      this.languages = this.allLanguages[this.samplePreview.gender];
      if (!languageId) {
        for (let i = 0; i < this.languages.length; i++) {
          if (this.languages[i].is_default) {
            this.selectedLanguage = this.languages[i].value;
          }
        }
      }
    },
  },
};
</script>
<style src="@vueform/multiselect/themes/default.css"></style>
<style scoped>
.carousel__prev--in-active,
.carousel__next--in-active {
  display: none;
}
</style>
