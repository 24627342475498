<template>
  <div class="sticky">
    <div class="text-black w-full px-10 flex justify-between items-center">
      <div v-if="expDetails" class="font-semibold text-xl relative">
        <img
          :src="require('@/assets/images/button-back.svg')"
          class="h-4 absolute -left-7 top-2 cursor-pointer"
          @click.prevent="goBack()"
        />
        <div>
          <p class="text-2xl">{{ expDetails.name }}</p>
          <p class=" font-normal text-base">{{ expDetails.description }}</p>
        </div>
      </div>
      <div class="flex flex-col md:flex-row place-content-end w-2/5">
        <div v-show="error" class="text-red-500 place-self-end p-3">
          <p class="text-md font-semibold">{{ error }}</p>
        </div>
        <div
          v-show="success"
          class="success text-green-500 place-self-end text-right"
        >
          <p class="text-sm md:text-base font-semibold">
            {{ success }}
          </p>
          <p class="text-sm text-gray-400">
            You will be redirected automatically . . .
          </p>
        </div>
        <div
          v-if="expDetails.flags.exp_in_kit"
          class="border border-nColorRed flex items-center px-2 rounded-lg cursor-pointer ml-2"
          @click.prevent="showRemoveModal"
        >
          <img :src="require('@/assets/images/trash.svg')" class="h-6" />
        </div>
        <FormButtons
          :showAddButton="showAddButton"
          :loading="loading"
          @click="addExperienceToKit"
          :addButtonEnabled="addButtonStatus"
        />
      </div>
    </div>
    <ConfirmationModal
      @closeModal="modal.show = false"
      @remove="removeExpFromKit"
      :modal="modal"
    />
  </div>
</template>

<script>
import ApiService from "@/common/api.service";
import { apiResource } from "@/common/app.config";
import FormButtons from "@/components/Elements/FormButtons.vue";
import ConfirmationModal from "@/components/Modals/ConfirmationModal.vue";

export default {
  props: {
    expDetails: { type: Object },
    experience_id: { type: String },
    onboarding_kit_id: { type: String },
    showAddButton: { type: Boolean },
    errorMsg: { type: String },
    successMsg: { type: String },
    loading: { type: Boolean },
    addButtonEnabled: { type: Boolean, default: () => true },
    hasWizard: { type: Boolean, default: () => false },
  },
  components: {
    FormButtons,
    ConfirmationModal,
  },
  created() {},
  mounted() {},
  data() {
    return {
      error: this.errorMsg,
      success: this.successMsg,
      addButtonStatus: this.addButtonEnabled,
      modal: {
        show: false,
        heading: "Remove experience from kit",
        question:
          "Are you sure you want to remove this experience from the onboarding kit?",
        action: "remove",
        showLottie: true,
        lottiePath: "lotties/warning.json",
      },
    };
  },
  methods: {
    updateSuccessErrorMsgs(successMsg, errorMsg) {
      this.success = successMsg;
      this.error = errorMsg;
    },
    updateEnableAddButton(status) {
      this.addButtonStatus = status;
    },
    addExperienceToKit() {
      if (this.addButtonStatus) {
        this.$emit("addExperienceToKit");
      }
    },
    goBack() {
      var backPage = window.history.state.back;
      if (backPage && !backPage.includes("create-journey/catalog/all")) {
        this.$router.back();
      } else {
        this.$router.push({
          name: "CreateOnboardingKitCatalog",
          params: {
            onboarding_kit_id: this.onboarding_kit_id,
            tag: "all",
          },
        });
      }
    },
    showRemoveModal() {
      this.modal.show = true;
    },
    removeExpFromKit() {
      ApiService.post(apiResource.onboardingKit.removeExperience, {
        onboarding_kit_id: this.onboarding_kit_id,
        experience_id: this.experience_id,
      })
        .then(({ data }) => {
          this.success = data.msg;
          setTimeout(() => {
            this.$router.push({
              name: "CreateOnboardingKitCatalog",
              params: {
                onboarding_kit_id: this.onboarding_kit_id,
                tag: "all",
              },
            });
          }, 2000);
        })
        .catch((error) => {
          this.error = error.response.data.msg;
        });
    },
  },
  computed: {},
  watch: {
    addButtonEnabled(newaddButtonEnabled, oldaddButtonEnabled) {
      if (oldaddButtonEnabled != newaddButtonEnabled) {
        this.addButtonStatus = this.addButtonEnabled;
      }
    },
  },
};
</script>
<style scoped lang="postcss">
div.sticky {
  position: -webkit-sticky;
  position: sticky;
  height: 80px;
  top: -16px;
  z-index: 1;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}
div.sticky::before,
div.sticky::after {
  content: "";
  display: block;
  height: 16px;
  /* make pseudo elements sticky as well */
  position: sticky;
  -webkit-position: sticky;
}

/* SHADOW */
div.sticky::before {
  top: 48px; /* shadow is at bottom of element, so at 48 + 16 = 64px */
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.5);
}

/* COVER */
div.sticky::after {
  /* linear gradient from background color to transparent acts as
     a transition effect so the shadow appears gradually */
  background: linear-gradient(
    white 10%,
    rgba(255, 255, 255, 0.8) 50%,
    rgba(255, 255, 255, 0.4) 70%,
    transparent
  );
  top: 0;
  /* cover should fall over shadow */
  z-index: 2;
}
div.sticky > div {
  background: white;
  height: 64px;
  position: sticky;
  -webkit-position: sticky;
  top: 0px;
  /* compensate for shadow with negative margin */
  margin-top: -8px;
  /* content should fall over shadow and cover */
  z-index: 3;
}
</style>
