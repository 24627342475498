<template>
  <div id="induction-video" class="relative text-white border p-4 rounded-lg">
    <button
      v-if="showIndex == 'script' || showIndex == 'complete'"
      class="back-button text-lg uppercase font-semibold text-gray-400 "
      @click.prevent="nextTab(back.slug)"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        class="h-6 w-6 inline-block mr-1"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          d="M10 19l-7-7m0 0l7-7m-7 7h18"
        />
      </svg>
      {{ back.text }}
    </button>
    <div class="relative w-3/4">
      <WizardStep :steps="wizardSteps" :selected="showIndex" />
    </div>

    <div class="iv-content hidden md:block mt-6">
      <form
        action=""
        enctype="multipart/form-data"
        @submit.prevent="openModal(modal)"
        novalidate
      >
        <div class="iv-content-company" v-show="showIndex == 'details'">
          <div class="flex flex-row justify-between">
            <h1 class="text-2xl font-semibold text-black">
              Company Details
            </h1>
            <p class="text-nColorMustard font-medium">
              Exclude any section from the video by turning them
              <span class="font-bold">OFF</span>
            </p>
          </div>
          <div class="company-details">
            <div>
              <div class="mt-1 ivCard">
                <h2 class="text-2xl font-semibold">
                  Hover on a thumbnail to preview and click to select
                  background.
                </h2>
                <div class="flex flex-wrap">
                  <div
                    class="theme-box"
                    v-for="theme in bgOptions"
                    :key="theme.slug"
                  >
                    <div class="selector mr-5">
                      <input
                        class="w-4 h-4 absolute opacity-0"
                        type="radio"
                        name="option"
                        :id="'option_' + theme.slug"
                        :value="theme.slug"
                        required
                        v-model="selectedTheme"
                      />
                      <label
                        @click.prevent="assignTheme(theme)"
                        class="ml-3 text-lg font-semibold cursor-pointer"
                        :for="'option_' + theme.slug"
                      >
                        <div class="min-h-36 h-36 relative">
                          <div
                            v-show="selectedTheme.slug === theme.slug"
                            class="absolute -top-4 -right-4 text-green-600 bg-white cursor-default rounded-full"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="currentColor"
                              class="w-8 bi bi-x-circle-fill"
                              viewBox="0 0 16 16"
                            >
                              <path
                                d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z"
                              />
                            </svg>
                          </div>
                          <video
                            loop
                            @mouseenter="videoPlay($event)"
                            @mouseleave="videoPause($event)"
                            :class="{
                              'border-green-600':
                                selectedTheme.slug === theme.slug,
                            }"
                            class="w-full rounded-lg border-2 border-gray-500"
                            :src="theme.preview"
                            muted
                          ></video>
                        </div>
                        <span class="inline-block mt-2">{{ theme.name }}</span>
                      </label>
                    </div>
                  </div>
                </div>

                <div class="text-sm font-semibold text-nColorRed mt-3">
                  <p v-if="v$.selectedTheme.$invalid && checkValidation">
                    {{ v$.selectedTheme.$silentErrors[0].$message }}
                  </p>
                  <p v-if="errObj && errObj.option">
                    {{ errObj.option }}
                  </p>
                </div>
              </div>

              <div class="company-name mt-2 ivCard">
                <div class="mb-4 flex">
                  <div class="name w-3/5">
                    <label for="companyName" class="ivInputLabel mr-2"
                      >Hi, we are</label
                    >
                    <input
                      class="text-3xl py-1 px-2 border rounded-lg font-semibold w-96"
                      type="text"
                      id="companyName"
                      name="companyName"
                      v-model="companyName"
                      placeholder="Company Name"
                    />
                  </div>
                </div>
                <p
                  class="text-lg font-semibold text-nColorRed"
                  v-if="v$.companyName.$invalid && checkValidation"
                >
                  {{ v$.companyName.$silentErrors[0].$message }}
                </p>
                <p v-else class="text-base">
                  Add company name, You can use capital letters as well.
                </p>
              </div>

              <div class="company-revenue mt-2 ivCard">
                <div class="mb-8 relative flex justify-between">
                  <div class="revenue">
                    <label for="revenue" class="ivInputLabel mr-2">A</label>
                    <input
                      class="text-3xl py-1 rounded-lg w-28 border text-center font-semibold "
                      placeholder="50"
                      type="text"
                      name="revenue"
                      id="revenue"
                      v-model="companyRevenue"
                    />
                    <label for="revenue" class="ivInputLabel ml-2"
                      >{{ revenueCurrency }} company.</label
                    >
                  </div>

                  <div class="text-center">
                    <p class="mb-2 text-black">
                      Include in the video
                    </p>
                    <Toggle
                      onLabel="Yes"
                      offLabel="No"
                      v-model="toggles.revenue"
                    />
                  </div>
                </div>
                <p
                  class="text-lg font-semibold text-nColorRed"
                  v-if="v$.companyRevenue.$invalid && checkValidation"
                >
                  {{ v$.companyRevenue.$silentErrors[0].$message }}
                </p>
                <p v-else class="text-base">Add company revenue</p>
              </div>

              <div class="company-locations mt-2 ivCard">
                <div class="mb-12 relative flex justify-between">
                  <div class="locations">
                    <label for="locations" class="ivInputLabel mr-2"
                      >Based out of</label
                    >
                    <div class="inline-block md:w-72 relative mb-32">
                      <input
                        @keydown.enter.prevent="
                          selectLocationKb(searchActiveItem)
                        "
                        @keydown.esc.prevent="suggestionShowFlag = false"
                        @keydown.down.prevent="searchActiveInc()"
                        @keydown.up.prevent="searchActiveDec()"
                        class="text-3xl py-1 px-2 border rounded-lg font-semibold w-full"
                        type="text"
                        name="locations"
                        id="locations"
                        placeholder="Add max 5 locations"
                        @input="debounceLocationInput"
                        v-model="locationInput"
                        autocomplete="off"
                      />
                      <ul
                        id="cities"
                        v-if="showSuggestions && suggestionShowFlag"
                        class="suggestions block rounded-lg shadow-lg p-2 absolute z-10 w-full bg-white"
                      >
                        <li
                          class="mb-1 rounded px-4 hover:bg-gray-200 focus:bg-gray-400 focus:text-white cursor-pointer"
                          v-for="(location, index) in filteredLocations"
                          :class="{ 'bg-gray-200': searchActiveItem == index }"
                          :key="location"
                          @click="selectLocation(location)"
                        >
                          {{ location }}
                        </li>
                      </ul>
                      <ul class="chosenLocations absolute mt-2 flex flex-wrap">
                        <li
                          class="bg-gray-200 rounded-full px-3 mx-1 flex justify-between my-1"
                          v-for="(location, index) in companyLocations"
                          :key="location"
                        >
                          <span>{{ location }}</span>

                          <button
                            class="inline"
                            @click.prevent="deleteLocation(index)"
                          >
                            <svg
                              class="w-4 text-nColorRed inline"
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke="currentColor"
                            >
                              <path
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="2"
                                d="M6 18L18 6M6 6l12 12"
                              />
                            </svg>
                          </button>
                        </li>
                      </ul>
                      <button
                        class="absolute right-0 top-1/2 transform -translate-y-1/2 mr-5"
                        v-if="locationInput.length > 0"
                        @click.prevent="locationInput = ''"
                      >
                        <svg
                          class="w-5 text-nColorRed"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="2"
                            d="M6 18L18 6M6 6l12 12"
                          />
                        </svg>
                      </button>
                    </div>
                  </div>

                  <div class="text-center">
                    <p class="text-md mb-2 text-black">
                      Include in the video
                    </p>
                    <Toggle
                      onLabel="Yes"
                      offLabel="No"
                      v-model="toggles.locations"
                    />
                  </div>
                </div>
                <p
                  class="text-lg font-semibold text-nColorRed"
                  v-if="v$.companyLocations.$invalid && checkValidation"
                >
                  {{ v$.companyLocations.$silentErrors[0].$message }}
                </p>
                <p v-else class="text-base">
                  Add locations in which you have offices.
                </p>
              </div>

              <div class="company-team mt-2 ivCard">
                <div class="mb-8 relative flex justify-between">
                  <div class="team">
                    <label for="employees" class="ivInputLabel mx-2"
                      >With a team of</label
                    >
                    <input
                      class="text-3xl py-1 border rounded-lg w-36 font-semibold text-center"
                      type="text"
                      placeholder="100000"
                      name="employees"
                      id="employees"
                      v-model="companyEmployees"
                    />
                    <label for="employees" class="ivInputLabel ml-2"
                      >employees.</label
                    >
                  </div>

                  <div class="text-center">
                    <p class="text-md mb-2 text-black">
                      Include in the video
                    </p>
                    <Toggle
                      onLabel="Yes"
                      offLabel="No"
                      v-model="toggles.team"
                    />
                  </div>
                </div>
                <p
                  class="text-lg text-nColorRed font-semibold"
                  v-if="v$.companyEmployees.$invalid && checkValidation"
                >
                  {{ v$.companyEmployees.$silentErrors[0].$message }}
                </p>
                <p v-else class="text-base">
                  Add your team size.
                </p>
              </div>

              <div class="company-years mt-2 ivCard">
                <div class="years relative flex justify-between">
                  <div class="years w-4/5">
                    <div class="founded mb-8 flex items-baseline">
                      <label for="years" class="ivInputLabel mr-2 block"
                        >We've been around for</label
                      >
                      <div class="w-1/2">
                        <input
                          placeholder="10"
                          class="text-3xl py-1 px-2 border rounded-lg font-semibold w-24 text-center inline-block"
                          type="text"
                          name="years"
                          id="years"
                          v-model="companyYears"
                        />
                        <label for="years" class="ml-2 ivInputLabel"
                          >years</label
                        >
                      </div>
                    </div>
                  </div>

                  <div class="text-center">
                    <p class="text-md mb-2 text-black">
                      Include in the video
                    </p>
                    <Toggle
                      onLabel="Yes"
                      offLabel="No"
                      v-model="toggles.founded_year"
                    />
                  </div>
                </div>
                <p
                  class="text-lg text-nColorRed font-semibold"
                  v-if="v$.companyYears.$invalid && checkValidation"
                >
                  {{ v$.companyYears.$silentErrors[0].$message }}
                </p>
              </div>

              <div class="company-industry mt-2 ivCard">
                <div class="industry mb-12 flex relative justify-between">
                  <div>
                    <label for="domain" class="ivInputLabel mr-2 inline-block"
                      >Industry</label
                    >
                    <div class="domain inline-block relative my-4">
                      <input
                        @keydown.enter.prevent="
                          selectDomainKb(searchActiveItem)
                        "
                        @keydown.esc.prevent="suggestionShowFlag = false"
                        @keydown.down.prevent="searchActiveInc()"
                        @keydown.up.prevent="searchActiveDec()"
                        class="text-3xl py-1 px-2 border rounded-lg font-semibold w-full"
                        type="text"
                        name="domain"
                        id="domain"
                        placeholder="Enter your domain"
                        @input="debounceDomainInput"
                        v-model="domainInput"
                        autocomplete="off"
                      />
                      <ul
                        id="cities"
                        v-if="showSuggestions && suggestionShowFlag"
                        class="suggestions block rounded-lg shadow-lg p-2 absolute z-10 w-full bg-white"
                      >
                        <li
                          @mouseover="searchActiveItem = index"
                          class="mb-1 rounded px-4 hover:bg-gray-200 focus:bg-gray-400 focus:text-white cursor-pointer"
                          v-for="(domain, index) in filteredDomains"
                          :class="{ 'bg-gray-200': searchActiveItem == index }"
                          :key="domain"
                          @click="selectDomain(domain)"
                        >
                          {{ domain }}
                        </li>
                      </ul>
                      <ul class="chosenLocations absolute mt-2 flex flex-wrap">
                        <li
                          class="bg-gray-200 rounded-full px-3 mx-1 flex justify-between my-1"
                        >
                          <span>{{ companyDomain }}</span>
                        </li>
                      </ul>
                      <button
                        class="absolute right-0 top-1/2 transform -translate-y-1/2 mr-5"
                        v-if="locationInput.length > 0"
                        @click.prevent="locationInput = ''"
                      >
                        <svg
                          class="w-5 text-nColorRed"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="2"
                            d="M6 18L18 6M6 6l12 12"
                          />
                        </svg>
                      </button>
                    </div>
                  </div>

                  <div class="text-center">
                    <p class="text-md mb-2 text-black">
                      Include in the video
                    </p>
                    <Toggle
                      onLabel="Yes"
                      offLabel="No"
                      v-model="toggles.industry"
                    />
                  </div>
                </div>
                <p
                  class="text-lg text-nColorRed font-semibold"
                  v-if="v$.companyDomain.$invalid && checkValidation"
                >
                  {{ v$.companyDomain.$silentErrors[0].$message }}
                </p>
                <p v-else class="text-base">
                  Select a domain.
                </p>
              </div>

              <div class="company-specialise mt-2 ivCard">
                <div class="specialise flex relative justify-between">
                  <div class="service">
                    <label for="years" class="ivInputLabel inline-block"
                      >Specialise in</label
                    >
                    <div class="relative inline-block md:w-96 h-48 ml-2">
                      <input
                        class="text-3xl py-1 px-4 rounded-lg border font-semibold w-full"
                        @keydown.enter.prevent="selectServiceKb()"
                        @keydown.esc.prevent="suggestionShowFlag = false"
                        type="text"
                        name="services"
                        id="services"
                        autocomplete="off"
                        placeholder="Application Development"
                        @input="debounceServiceInput"
                        v-model="serviceInput"
                      />
                      <ul
                        v-if="capService != '' && suggestionShowFlag"
                        class="suggestions ml-1 block rounded-lg shadow-lg p-2 absolute z-10 bg-white"
                      >
                        <li
                          class="rounded px-4 hover:bg-gray-200 focus:bg-gray-400 focus:text-white cursor-pointer"
                          @click="selectService(capService)"
                        >
                          {{ capService }}
                        </li>
                      </ul>
                      <ul class="absolute mt-2 flex flex-wrap">
                        <li
                          class="bg-gray-200 rounded-full px-3 mx-1 flex justify-between my-1"
                          v-for="(location, index) in companyServices"
                          :key="location"
                        >
                          <span>{{ location }}</span>

                          <button
                            class="inline"
                            @click.prevent="deleteService(index)"
                          >
                            <svg
                              class="w-4 text-nColorRed inline"
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke="currentColor"
                            >
                              <path
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="2"
                                d="M6 18L18 6M6 6l12 12"
                              />
                            </svg>
                          </button>
                        </li>
                      </ul>
                    </div>
                  </div>

                  <div class="text-center">
                    <p class="text-md mb-2 text-black">
                      Include in the video
                    </p>
                    <Toggle
                      onLabel="Yes"
                      offLabel="No"
                      v-model="toggles.services"
                    />
                  </div>
                </div>
                <p
                  class="text-lg text-nColorRed font-semibold"
                  v-if="v$.companyServices.$invalid && checkValidation"
                >
                  {{ v$.companyServices.$silentErrors[0].$message }}
                </p>
                <p v-else class="text-base">
                  Search and add services.
                </p>
              </div>

              <div class="values ivCard mt-2">
                <div class="flex relative justify-between">
                  <div class="value-container relative">
                    <div class="grid w-11/12">
                      <div class="flex justify-between">
                        <label class="ivInputLabel">We value</label>
                        <button
                          class="w-20 text-nColorRed font-semibold rounded border-red-400 border px-3 py-1
                hover:bg-red-400 hover:text-white transition-all duration-300 ease-in-out uppercase place-self-end"
                          @click.prevent="companyValues = []"
                        >
                          clear
                        </button>
                      </div>
                      <div
                        class="mt-4 mb-8 select flex flex-wrap justify-start border p-4 rounded-xl bg-white"
                      >
                        <div
                          class="m-4"
                          v-for="(value, i) in values"
                          :key="value"
                          @click="checkDisabled()"
                        >
                          <input
                            class="hidden"
                            type="checkbox"
                            name="companyValues"
                            :id="value + i"
                            :value="value"
                            :disabled="
                              companyValues.length > checkboxLimit - 1 &&
                                companyValues.indexOf(value) === -1
                            "
                            v-model="companyValues"
                          />
                          <label
                            :class="{
                              'border border-nColorBlue': companyValues.includes(
                                value
                              ),
                            }"
                            class="py-2 px-6 rounded-full cursor-pointer hover:shadow-lg transition
                      duration-400 ease-out border"
                            :for="value + i"
                            >{{ value }}</label
                          >
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="min-w-max text-center ">
                    <p class="text-md mb-2 text-black">
                      Include in the video
                    </p>
                    <Toggle
                      onLabel="Yes"
                      offLabel="No"
                      v-model="toggles.values"
                    />
                  </div>
                </div>
                <p
                  class="text-lg font-semibold text-nColorRed"
                  v-if="v$.companyValues.$invalid && checkValidation"
                >
                  {{ v$.companyValues.$silentErrors[0].$message }}
                </p>
                <p v-else class="text-base">
                  Choose any 5 words that represent the culture of your company
                  the best
                </p>
              </div>
              <div class="companyVideo ivCard mt-2">
                <div class="relative flex justify-between">
                  <div class="companyVideo-container w-full">
                    <label class="ivInputLabel">Upload a video.</label>
                    <div class="flex gap-4">
                      <div class="last-upload my-8" v-if="companyVideo">
                        <div class="flex items-center">
                          <input
                            type="radio"
                            name="testimonialVideoSelect"
                            v-model="videoSelects.company"
                            value="prev"
                            id="testimonialVideoSelectPrev"
                            class="inline-block mr-3 w-5 h-5"
                            @change="toggles.video = true"
                          />
                          <label
                            for="testimonialVideoSelectPrev"
                            class="text-xl font-semibold"
                            >Previous Upload</label
                          >
                        </div>
                        <video
                          controls
                          class="md:w-80 lg:w-96 rounded-xl mt-4"
                          :src="companyVideo.url"
                        ></video>
                      </div>

                      <div class="w-full mt-8">
                        <div class="flex items-center">
                          <input
                            type="radio"
                            value="new"
                            name="companyVideoSelect"
                            v-model="videoSelects.company"
                            id="companyVideoSelectNew"
                            class="inline-block mr-3 w-5 h-5"
                            @change="toggles.video = true"
                          />
                          <label
                            for="companyVideoSelectNew"
                            class="text-xl font-semibold inline-block"
                            >New Upload</label
                          >
                        </div>
                        <div class="w-full mt-4 flex items-center gap-2">
                          <div class="file-input-video">
                            <FileInput
                              :acceptedFileTypes="['mov', 'mp4']"
                              ref="companyVideo"
                              inputId="companyVideo"
                              inputName="companyVideo"
                              @change="setVideo($event, 'companyVideo')"
                            />
                          </div>
                          <div class="-mt-6">
                            <button
                              class="text-nColorRed"
                              v-if="companyVideo"
                              @click.prevent="unsetVideo('companyVideo')"
                            >
                              <svg
                                class="w-5"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                              >
                                <path
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  stroke-width="2"
                                  d="M6 18L18 6M6 6l12 12"
                                />
                              </svg>
                            </button>
                            <div v-else class="inline-block w-5">&nbsp;</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="min-w-max text-center">
                    <p class="text-md mb-2 text-black">
                      Include in the video
                    </p>
                    <Toggle
                      onLabel="Yes"
                      offLabel="No"
                      v-model="toggles.video"
                    />
                  </div>
                </div>
              </div>
              <div class="logo ivCard mt-2">
                <label class="ivInputLabel block mb-10">Add company logo</label>
                <div
                  :class="{
                    'bg-white': logoBg == 'light',
                    'bg-black': logoBg == 'dark',
                  }"
                  class="logo-container max-w-32 h-32 max-h-32 relative flex justify-center items-center border"
                >
                  <img
                    class="w-full h-full object-contain"
                    :src="logo"
                    alt=""
                    ref="companyLogo"
                  />
                </div>
                <div class="flex mt-4">
                  <div class="w-2/5">
                    <FileInput
                      :acceptedFileTypes="['png', 'jpg', 'jpeg', 'svg']"
                      ref="companyLogoInput"
                      inputId="companyLogo"
                      inputName="companyLogo"
                      @change="setLogo($event)"
                    />
                  </div>
                  <div class="w-3/5 h-14 flex justify-start">
                    <button
                      class="text-nColorRed ml-2"
                      v-if="companyLogo"
                      @click.prevent="unSetLogo()"
                    >
                      <svg
                        class="w-5"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          d="M6 18L18 6M6 6l12 12"
                        />
                      </svg>
                    </button>
                  </div>
                </div>
                <div class="logo-bg-options mt-8 mb-4">
                  <h3 class="text-lg">Select background color for the logo</h3>
                  <div
                    class="logo-bg-option inline-block mt-1"
                    v-for="(value, color) in logoBgOptions"
                    :key="color"
                  >
                    <input
                      class="w-0 h-0 absolute opacity-0"
                      type="radio"
                      name="option"
                      :id="'option_' + color"
                      :value="color"
                      required
                      v-model="logoBg"
                    />
                    <label
                      class="text-base font-semibold cursor-pointer"
                      :for="'option_' + color"
                    >
                      <div
                        :class="{
                          'bg-white': color === 'light',
                          'bg-black': color === 'dark',
                          'border-2 border-nColorMustard rounded-xl':
                            logoBg === color,
                        }"
                        class="preview mr-6 w-16 h-16 border-2 rounded-xl"
                      ></div>
                      <span class="inline-block mt-2 ml-1 uppercase">{{
                        color
                      }}</span>
                    </label>
                  </div>
                </div>
                <p
                  class="text-lg text-nColorRed font-semibold"
                  v-if="
                    v$.companyLogo && v$.companyLogo.$invalid && checkValidation
                  "
                >
                  {{ v$.companyLogo.$silentErrors[0].$message }}
                </p>
              </div>
            </div>
          </div>
          <div class="flex place-content-end mt-4">
            <div v-if="errorMsg" class="text-nColorRed place-self-end p-3">
              <p class="text-md font-semibold">{{ errorMsg }}</p>
            </div>
            <p
              class="text-nColorRed font-semibold place-self-end p-3"
              v-if="v$.$invalid && checkValidation"
            >
              Some fields need your attention.
            </p>
            <div>
              <button
                @click.prevent="onProcessData()"
                class="shadow transition duration-300 ease-out text-white tracking-wider px-5 py-2 bg-nButtonGreen rounded-lg 
                     hover:bg-green-500 hover:shadow-lg uppercase font-bold"
              >
                Next
              </button>
            </div>
          </div>
        </div>
        <div class="rv-content-script" v-if="showIndex == 'script'">
          <div
            class="rv-script bg-nColorFillGray border border-nColorStrokeGray rounded-xl text-black p-4"
          >
            <h2 class="text-xl font-semibold">
              This is how your video will flow. Reorder the sections.
              <span class=" text-gray-400 italic text-sm"> (if required)</span>
            </h2>
            <p
              class="bg-nProfileList rounded-lg flex py-1 mb-2 text-gray-400 font-semibold w-1/2 mt-4"
            >
              <span class="ml-6 my-2 text-md cursor-default">
                <strong>Company logo</strong> will appear throughout the video.
              </span>
            </p>
            <p
              class="bg-nProfileList rounded-lg flex py-1 mb-2 text-gray-400 font-semibold w-1/2"
            >
              <span class="ml-6 my-2 text-md cursor-default">{{
                scriptText("company_name")
              }}</span>
            </p>
            <div class="content text-black w-1/2 ">
              <draggable
                class="list-group"
                tag="transition-group"
                :component-data="{
                  tag: 'ul',
                  type: 'transition-group',
                  name: !drag ? 'flip-list' : null,
                }"
                v-model="toggledFields"
                v-bind="dragOptions"
                @start="drag = true"
                @end="drag = false"
                item-key="name"
              >
                <template v-slot:item="{ item }">
                  <li
                    v-if="item.name != 'video'"
                    v-show="item.name != 'logo' && item.name != 'company_name'"
                    :key="item.name"
                    class="bg-nProfileList rounded-lg flex py-1 mb-2 justify-between items-center cursor-move"
                  >
                    <p class="ml-6 font-semibold text-lg py-1 inline-block">
                      {{ scriptText(item.name) }}
                    </p>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="h-7 w-7 inline-block mr-10 text-gray-400"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M4 6h16M4 10h16M4 14h16M4 18h16"
                      />
                    </svg>
                  </li>
                  <li
                    v-else
                    v-show="item.name != 'logo'"
                    :key="item.name"
                    class="bg-nProfileList rounded-lg flex py-1 mb-2 justify-between items-center cursor-move"
                  >
                    <p class="ml-6 font-semibold text-lg py-1 inline-block">
                      Uploaded Video
                    </p>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="h-7 w-7 inline-block mr-10 text-gray-400"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M4 6h16M4 10h16M4 14h16M4 18h16"
                      />
                    </svg>
                  </li>
                </template>
              </draggable>
            </div>
          </div>
          <div class="flex place-content-end mt-4">
            <ButtonInput
              buttonText="Preview"
              :loading="loading"
              :buttonClass="
                'transition shadow duration-300 ease-out tracking-wider bg-nButtonGreen text-white py-2 px-5 rounded-lg uppercase font-bold ml-4'
              "
              :loadingClass="
                'cursor-not-allowed shadow text-white tracking-wider py-2 px-5 bg-nButtonGreen rounded-lg uppercase font-bold ml-4'
              "
            />
          </div>
        </div>
        <div class="content-render" v-if="showIndex == 'render'">
          <div
            class="loading-image w-2/3 relative left-1/2 transform -translate-x-1/2"
          >
            <lottie-animation
              path="lotties/cycleAnimation.json"
              :loop="true"
              :autoPlay="true"
              :speed="1"
            />
            <div class="text-2xl text-center text-black">
              Rendering your preview . . .
            </div>
          </div>
        </div>
      </form>
      <div class="content-complete" v-if="showIndex == 'complete'">
        <div
          class="inline-player flex flex-col bg-nColorFillGray border border-nColorStrokeGray rounded-xl text-black"
        >
          <VideoPlayer
            :identifier="'previewVideo'"
            v-if="previewUrl"
            :src="previewUrl"
          />
        </div>
      </div>
    </div>

    <ConfirmationModal
      @process="onSubmit()"
      @closeModal="modal.show = $event"
      :modal="modal"
    ></ConfirmationModal>

    <div
      class="block-overlay block md:hidden absolute top-0 left-0 w-full h-64 bg-white rounded-lg"
    >
      <p class="text-3xl text-black px-4 text-center mt-20">
        Please view this experience on Desktop
      </p>
    </div>
  </div>
</template>

<script>
import ApiService from "@/common/api.service";
import { loadProgressBar } from "axios-progress-bar";
import "axios-progress-bar/dist/nprogress.css";
import { apiResource, ivFields } from "@/common/app.config";
import useVuelidate from "@vuelidate/core";
import { required, helpers } from "@vuelidate/validators";
import { debounce } from "debounce";
import ButtonInput from "@/components/Elements/ButtonInput";
import LottieAnimation from "lottie-vuejs/src/LottieAnimation.vue";
import ConfirmationModal from "@/components/Modals/ConfirmationModal";
import Toggle from "@vueform/toggle";
import Draggable from "vue3-draggable";
import { messages } from "@/common/messages.js";
import FileInput from "@/components/Elements/FileInput.vue";
import WizardStep from "@/components/Elements/WizardStep.vue";
import VideoPlayer from "@/components/Elements/VideoPlayer.vue";
import { useToast } from "vue-toastification";

const toast = useToast();

export default {
  name: "InductionVideoForm",
  props: {
    expDetails: {
      type: Object,
      required: true,
    },
    bgOptions: {
      type: Array,
      required: true,
    },
    inputToggles: {
      type: Object,
      required: true,
    },
    logoBgOptions: {
      type: Object,
    },
    onboarding_kit_id: {
      type: String,
    },
    experience_id: {
      type: String,
    },
  },
  components: {
    ButtonInput,
    LottieAnimation,
    ConfirmationModal,
    Toggle,
    Draggable,
    FileInput,
    WizardStep,
    VideoPlayer,
  },
  created() {
    if (this.expDetails.flags.exp_in_kit) {
      this.added = true;
    }
  },
  mounted() {
    ApiService.get(
      apiResource.onboardingKit.experiences.inductionVideo.metaData
    )
      .then((data) => {
        this.locations = data.data.data.locations;
        this.values = data.data.data.values;
        this.domains = data.data.data.domains;
      })
      .catch(() => {});
    ApiService.get(
      apiResource.onboardingKit.experiences.inductionVideo.getDetails,
      {
        onboarding_kit_id: this.onboarding_kit_id,
        experience_id: this.experience_id,
      }
    )
      .then((data) => {
        this.logo = data.data.data.details.logo.url;
        this.locations = data.data.data.details.locations;
        this.values = data.data.data.details.values;
        this.domains = data.data.data.details.domains;
        this.companyName = data.data.data.details.company_name;
        this.companyRevenue = data.data.data.details.revenue;
        this.companyLocations = data.data.data.details.locations;
        this.companyValues = data.data.data.details.values;
        this.companyServices = data.data.data.details.services;
        this.companyDomain = data.data.data.details.domains;
        this.companyEmployees = data.data.data.details.no_of_employees;
        this.companyYears = data.data.data.details.no_of_years;

        if (data.data.data.details.video) {
          this.companyVideo = data.data.data.details.video;
          this.videoSelects.company = "prev";
          this.toggles.video = true;
        }
      })
      .catch(() => {});

    this.toggles = { ...this.inputToggles };

    loadProgressBar({
      showSpinner: false,
      trickleSpeed: 2000,
      speed: 1000,
    });
  },
  setup() {
    return { v$: useVuelidate() };
  },
  data() {
    return {
      selectedTheme: {
        slug: "",
        name: "",
        type: "",
        preview: "",
        video: "",
        audio: "",
      },
      companyName: null,
      companyDomain: null,
      companyEmployees: null,
      companyRevenue: null,
      companyYears: null,
      logo: null,
      backgrounds: {},
      bgPreviews: {},
      companyServices: [],
      companyValues: [],
      companyLocations: [],
      companyWorking: [],
      companyLogo: null,
      companyVideo: null,
      filteredLocations: [],
      filteredDomains: [],
      searchActiveItem: 0,
      suggestionShowFlag: true,
      capService: "",
      locationInput: "",
      domainInput: "",
      serviceInput: "",
      logoBg: "dark",
      values: [],
      domains: [],
      locations: [],
      showIndex: "details",
      toggles: {},
      toggledFields: [],
      checkboxLimit: 5,
      revenueCurrency: "USD",
      successMsg: null,
      previewUrl: null,
      errorMsg: null,
      errObj: null,
      checkValidation: false,
      loading: false,
      formData: null,
      added: false,
      uploadPercentage: 0,
      modal: {
        show: false,
        heading: "Process Induction Video",
        question:
          "Are you sure you want to process the information submitted and preview the induction video?",
        action: "process",
        showLottie: true,
        lottiePath: "lotties/create-video.json",
      },
      bgModal: {
        show: false,
        field: "companyName",
      },
      drag: false,
      wizardSteps: ["Details", "Script", "Render", "Complete"],
      videoSelects: {
        company: "",
      },
    };
  },
  validations() {
    let validation = {
      selectedTheme: {
        slug: { required },
      },
      companyName: {
        required: helpers.withMessage(
          messages.inductionVideo.company_name.required,
          required
        ),
      },
      companyDomain: {},
      companyEmployees: {},
      companyRevenue: {},
      companyYears: {},
      companyServices: {},
      companyValues: {},
      companyLocations: {},
    };

    if (this.toggles.revenue) {
      validation.companyRevenue = {
        required: helpers.withMessage(
          messages.inductionVideo.revenue.required,
          required
        ),
      };
    }

    if (this.toggles.locations) {
      validation.companyLocations = {
        required: helpers.withMessage(
          messages.inductionVideo.locations.required,
          required
        ),
      };
    }

    if (this.toggles.team) {
      validation.companyEmployees = {
        required: helpers.withMessage(
          messages.inductionVideo.team.required,
          required
        ),
      };
    }
    if (this.toggles.founded_year) {
      validation.companyYears = {
        required: helpers.withMessage(
          messages.inductionVideo.founded_year.required,
          required
        ),
      };
    }

    if (this.toggles.industry) {
      validation.companyDomain = {
        required: helpers.withMessage(
          messages.inductionVideo.industry.required,
          required
        ),
      };
    }

    if (this.toggles.services) {
      validation.companyServices = {
        required: helpers.withMessage(
          messages.inductionVideo.services.required,
          required
        ),
      };
    }

    if (this.toggles.values) {
      validation.companyValues = {
        required: helpers.withMessage(
          messages.inductionVideo.values.required,
          required
        ),
      };
    }

    if (!this.logo) {
      validation.companyLogo = {
        required: helpers.withMessage(
          messages.inductionVideo.logo.required,
          required
        ),
      };
    }
    return validation;
  },

  computed: {
    showSuggestions() {
      if (this.locationInput.length > 2 || this.domainInput.length > 2)
        return true;
      else return false;
    },
    dragOptions() {
      return {
        animation: 200,
        group: "description",
        disabled: false,
        ghostClass: "ghost",
      };
    },
    back() {
      if (this.showIndex == "script")
        return { slug: "details", text: "back to details" };
      else if (this.showIndex == "details")
        return { slug: "bg", text: "back to background" };
      else if (this.showIndex == "complete")
        return { slug: "script", text: "back to script" };
      else return "";
    },
  },
  methods: {
    assignTheme(theme) {
      this.selectedTheme = theme;
      this.nextTab("details");
    },
    assignFieldBg(e) {
      this.bgModal.show = false;
      this.backgrounds[e.field] = e.bg.slug;
      this.bgPreviews[e.field] = e.bg.preview;
    },
    videoPlay(e) {
      let video = e.currentTarget;
      video.play();
    },
    videoPause(e) {
      let video = e.currentTarget;
      video.pause();
    },
    debounceLocationInput: debounce(function() {
      this.searchActiveItem = 0;
      if (this.locationInput.length > 2) {
        this.suggestionShowFlag = true;
        let captalizeSearchString =
          this.locationInput[0].toUpperCase() + this.locationInput.slice(1);
        this.filteredLocations = this.locations
          .filter((location) => location.includes(captalizeSearchString))
          .slice(0, 5);

        if (this.filteredLocations.length == 0) {
          this.filteredLocations.push(captalizeSearchString);
        }
      } else {
        this.filteredLocations = [];
        return;
      }
    }, 500),
    debounceDomainInput: debounce(function() {
      this.searchActiveItem = 0;
      if (this.domainInput.length > 2) {
        this.suggestionShowFlag = true;
        let captalizeSearchString =
          this.domainInput[0].toUpperCase() + this.domainInput.slice(1);
        this.filteredDomains = this.domains
          .filter((domain) => domain.includes(captalizeSearchString))
          .slice(0, 5);

        if (this.filteredDomains.length == 0) {
          this.filteredDomains.push(captalizeSearchString);
        }
      } else {
        this.filteredDomains = [];
        return;
      }
    }, 500),
    debounceServiceInput: debounce(function() {
      this.searchActiveItem = 0;
      if (this.serviceInput.length > 2) {
        this.suggestionShowFlag = true;
        let captalizeSearchString =
          this.serviceInput[0].toUpperCase() + this.serviceInput.slice(1);
        this.capService = captalizeSearchString;
      } else {
        this.capService = "";
        return;
      }
    }, 500),
    nextTab(nextIndex) {
      this.showIndex = nextIndex;
    },
    selectLocation(location) {
      this.locationInput = location;
      this.companyLocations.push(location);
      this.locationInput = "";
    },
    deleteLocation(index) {
      this.companyLocations.splice(index, 1);
    },
    deleteService(index) {
      this.companyServices.splice(index, 1);
    },
    selectDomain(domain) {
      this.domainInput = domain;
      this.companyDomain = domain;
      this.domainInput = "";
    },
    selectService(service) {
      this.suggestionShowFlag = false;
      this.serviceInput = service;
      this.companyServices.push(service);
      this.serviceInput = "";
    },
    setLogo(event) {
      this.companyLogo = event.target.files[0];
      let vue = this;
      var reader = new FileReader();
      reader.onload = function(e) {
        vue.$refs.companyLogo.src = e.target.result;
      };
      reader.readAsDataURL(event.target.files[0]);
    },
    unSetLogo() {
      this.companyLogo = null;
      this.$refs.companyLogo.src = this.logo;
      this.$refs.companyLogoInput.resetComponent();
    },
    searchActiveInc() {
      if (
        this.searchActiveItem < this.filteredLocations.length - 1 ||
        this.searchActiveItem < this.filteredDomains.length - 1
      ) {
        this.searchActiveItem += 1;
      }
    },
    searchActiveDec() {
      if (this.searchActiveItem > 0) {
        this.searchActiveItem -= 1;
      }
    },
    selectLocationKb(index) {
      if (this.locationInput != "" && this.filteredLocations.length) {
        this.locationInput = this.filteredLocations[index];
        this.companyLocations.push(this.locationInput);
        this.suggestionShowFlag = false;
        this.locationInput = "";
        this.filteredLocations = [];
      } else {
        return;
      }
    },
    selectDomainKb(index) {
      if (this.domainInput != "" && this.filteredDomains.length) {
        this.domainInput = this.filteredDomains[index];
        this.companyDomain = this.domainInput;
        this.suggestionShowFlag = false;
        this.domainInput = "";
        this.filteredDomains = [];
      } else {
        return;
      }
    },
    selectServiceKb() {
      if (this.serviceInput != "" && this.capService != "") {
        this.companyServices.push(this.capService);
        this.suggestionShowFlag = false;
        this.serviceInput = "";
        this.capService = "";
      } else {
        return;
      }
    },
    openModal(modal) {
      this.checkValidation = true;
      if (!this.v$.$invalid) {
        modal.show = true;
      }
    },
    openBgModal(field) {
      this.bgModal.field = field;
      this.bgModal.show = true;
    },
    scriptText(field) {
      if (field == "company_name") return `Hi, We are ${this.companyName}`;

      if (field == "revenue") return `A ${this.companyRevenue} Company`;

      if (field == "locations")
        return `Based out of ${this.$filters.arrayCommaString(
          this.companyLocations
        )}`;

      if (field == "team")
        return `With a team of ${this.companyEmployees} employees`;

      if (field == "founded_year") {
        return `We've been around for ${this.companyYears} years`;
      }

      if (field == "industry") {
        return `Industry: ${this.companyDomain}`;
      }

      if (field == "services") {
        return `Specialise in ${this.$filters.arrayCommaString(
          this.companyServices
        )}`;
      }

      if (field == "values") {
        return `We value ${this.$filters.arrayCommaString(this.companyValues)}`;
      }

      if (field == "video") {
        return `Uploaded Video`;
      }
    },
    onSubmit() {
      if (!this.v$.$invalid) {
        this.loading = true;

        this.formData.append("script", JSON.stringify(this.toggledFields));
        this.nextTab("render");

        ApiService.post(
          apiResource.onboardingKit.experiences.inductionVideo.saveDetails,
          this.formData
        )
          .then((data) => {
            this.errorMsg = null;
            this.loading = false;
            this.previewUrl = data.data.data.preview;
            this.successMsg = data.data.msg;
            this.nextTab("complete");
            this.$emit("changeAddButtonStatus", true);
          })
          .catch((error) => {
            this.loading = false;
            if (error.response) {
              this.errorMsg = error.response.data.msg;
            }
            this.nextTab("details");
          });
      }
    },
    onProcessData() {
      this.checkValidation = true;
      if (!this.v$.$invalid) {
        this.nextTab("script");

        this.formData = new FormData();
        this.formData.append("onboarding_kit_id", this.onboarding_kit_id);
        this.formData.append("experience_id", this.experience_id);

        this.formData.append("option", this.selectedTheme.slug);

        if (this.companyLogo) {
          this.formData.append("logo", this.companyLogo);
        }

        this.formData.append("logo_background_type", this.logoBg);

        this.formData.append("company_name", this.companyName);

        this.formData.append("revenue", `${this.companyRevenue}`);

        this.formData.append(
          "locations",
          JSON.stringify(this.companyLocations)
        );

        this.formData.append("no_of_employees", this.companyEmployees);

        this.formData.append("no_of_years", this.companyYears);

        this.formData.append("services", JSON.stringify(this.companyServices));

        this.formData.append("domains", this.companyDomain);

        this.formData.append("values", JSON.stringify(this.companyValues));

        if (this.companyVideo) {
          this.formData.append("video", this.companyVideo);
        }

        this.toggledFields = ivFields.filter(
          (field) => this.toggles[field.name]
        );

        for (let field of this.toggledFields) {
          if (!this.backgrounds[field.name]) {
            field.option = this.selectedTheme.slug;
          } else {
            field.option = this.backgrounds[field.name];
          }
        }
      }
    },
    setVideo(event, videovar) {
      this.toggles.video = true;
      this[videovar] = event.target.files[0];
      this.videoSelects.company = "new";
    },

    unsetVideo(videovar) {
      this.toggles.video = false;
      this.companyVideo = null;
      this.$refs[videovar].value = "";
    },
    checkDisabled() {
      if (this.companyValues.length > this.checkboxLimit - 1) {
        toast.warning("You can select a maximum of 5 values only.", {
          timeout: 3000,
        });
      }
    },
  },
};
</script>
<style src="@vueform/toggle/themes/default.css"></style>
<style scoped lang="postcss">
.ivCard {
  @apply bg-nColorFillGray border border-nColorStrokeGray rounded-xl p-8 w-full text-black;
}

.ivInputLabel {
  @apply text-3xl font-bold mb-2;
}

.ivTextInput {
  @apply text-3xl px-4 border-b border ml-2;
}
.progress-number {
  border-width: 10px;
}
input::file-selector-button {
  font-family: "Rajdhani";
  border: solid 1px #07003b73;
  padding: 0.25em 0.8em;
  border-radius: 10px;
  cursor: pointer;
  transition: 1s;
  margin-right: 16px;
}

.max-w-32 {
  max-width: 128px;
}
.max-h-32 {
  max-height: 128px;
}
.iv {
  --toggle-width: 4rem;
  --toggle-font-size: 1rem;
}

.flip-list-move {
  transition: transform 0.5s;
}

.no-move {
  transition: transform 0s;
}

.ghost {
  opacity: 0.5;
  background: #c8ebfb;
}
.theme-box {
  width: 17rem;
}
.file-input-video {
  width: 34rem;
}
progress[value]::-webkit-progress-bar {
  background-color: #eee;
  border-radius: 2px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.25) inset;
}
</style>
