<template>
  <div class="mx-auto relative ml-2.5" v-if="experienceDetails">
    <Navbar class="px-10" />
    <div class="w-full">
      <WelcomeVideo
        v-if="experienceDetails.slug == slugsConfig.welcomeVideo"
        :expDetails="experienceDetails"
        :experience_id="experience_id"
        :onboarding_kit_id="onboarding_kit_id"
      />
      <EVP
        :experience_id="experience_id"
        :onboarding_kit_id="onboarding_kit_id"
        :expDetails="experienceDetails"
        v-else-if="experienceDetails.slug === slugsConfig.evp"
      />
      <CandidateExpSurvey
        :experience_id="experience_id"
        :onboarding_kit_id="onboarding_kit_id"
        :expDetails="experienceDetails"
        v-else-if="experienceDetails.slug === slugsConfig.candidateExpSurvey"
      />
      <InductionVideo
        :experience_id="experience_id"
        :onboarding_kit_id="onboarding_kit_id"
        :expDetails="experienceDetails"
        :inputToggles="experienceDetails.exp_data.toggles"
        :bgOptions="experienceDetails.exp_data.options"
        :logoBgOptions="experienceDetails.exp_data.logo_background_options"
        v-else-if="experienceDetails.slug === slugsConfig.inductionVideo"
      />
      <DocumentLibrary
        :expDetails="experienceDetails"
        v-else-if="experienceDetails.slug == slugsConfig.documentLibrary"
        :experience_id="experience_id"
        :onboarding_kit_id="onboarding_kit_id"
      />
      <NHA
        :experience_id="experience_id"
        :onboarding_kit_id="onboarding_kit_id"
        :expDetails="experienceDetails"
        v-else-if="experienceDetails.slug === slugsConfig.nha"
      />
      <Welcome360
        :experience_id="experience_id"
        :onboarding_kit_id="onboarding_kit_id"
        :expDetails="experienceDetails"
        v-else-if="experienceDetails.slug === slugsConfig.welcome360"
      />
      <TTAL
        :expDetails="experienceDetails"
        v-else-if="experienceDetails.slug === slugsConfig.ttal"
        :onboarding_kit_id="onboarding_kit_id"
        :experience_id="experience_id"
      />
      <ScavengerHunt
        :expDetails="experienceDetails"
        v-else-if="experienceDetails.slug == slugsConfig.scavenger"
        :experience_id="experience_id"
        :onboarding_kit_id="onboarding_kit_id"
      />
      <GTM
        v-else-if="experienceDetails.slug === slugsConfig.gtm"
        :expDetails="experienceDetails"
        :experience_id="experience_id"
        :onboarding_kit_id="onboarding_kit_id"
      />
      <ManagerNPS
        :expDetails="experienceDetails"
        v-else-if="experienceDetails.slug == slugsConfig.managerNPS"
        :experience_id="experience_id"
        :onboarding_kit_id="onboarding_kit_id"
      />
      <Pulse1Week
        :expDetails="experienceDetails"
        v-else-if="experienceDetails.slug == slugsConfig.pulseOneWeek"
        :experience_id="experience_id"
        :onboarding_kit_id="onboarding_kit_id"
      />
    </div>
  </div>
</template>

<script>
import ApiService from "@/common/api.service";
import { apiResource } from "@/common/app.config";
import Navbar from "@/components/Common/Navbar/Plain.vue";
import WelcomeVideo from "@/components/OnboardingKit/Experiences/WelcomeVideo/Index.vue";
import EVP from "@/components/OnboardingKit/Experiences/EVP/Index.vue";
import CandidateExpSurvey from "@/components/OnboardingKit/Experiences/CandidateExpSurvey/Index.vue";
import InductionVideo from "@/components/OnboardingKit/Experiences/InductionVideo/Index.vue";
import DocumentLibrary from "@/components/OnboardingKit/Experiences/DocumentLibrary/Index.vue";
import NHA from "@/components/OnboardingKit/Experiences/NHA/Index.vue";
import Welcome360 from "@/components/OnboardingKit/Experiences/Welcome360/Index.vue";
import TTAL from "@/components/OnboardingKit/Experiences/TTAL/Index.vue";
import ScavengerHunt from "@/components/OnboardingKit/Experiences/ScavengerHunt/Index.vue";
import GTM from "@/components/OnboardingKit/Experiences/GTM/Index.vue";
import ManagerNPS from "@/components/OnboardingKit/Experiences/ManagerNPS/Index.vue";
import Pulse1Week from "@/components/OnboardingKit/Experiences/Pulse1Week/Index.vue";
import slugsConfig from "@/common/slugs.config";

export default {
  name: "ExperienceForm",
  components: {
    Navbar,
    WelcomeVideo,
    EVP,
    CandidateExpSurvey,
    InductionVideo,
    DocumentLibrary,
    NHA,
    Welcome360,
    TTAL,
    ScavengerHunt,
    GTM,
    ManagerNPS,
    Pulse1Week,
  },
  created() {
    ApiService.get(apiResource.onboardingKit.experienceDetails, {
      onboarding_kit_id: this.onboarding_kit_id,
      experience_id: this.experience_id,
    })
      .then(({ data }) => {
        this.experienceDetails = data.data;
      })
      .catch(() => {});
  },
  data() {
    return {
      experienceDetails: null,
      onboarding_kit_id: this.$route.params.onboarding_kit_id,
      experience_id: this.$route.params.experience_id,
      slugsConfig,
    };
  },
  methods: {},
};
</script>

<style scoped lang="postcss"></style>
