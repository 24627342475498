<template>
  <div
    v-if="values"
    class="px-4 pt-5 pb-4 mb-0 bg-white border border-nColorStrokeGray rounded-xl flex justify-center"
  >
    <div class="w-3/4">
      <div id="introduction" class="grid">
        <div class="flex">
          <div class="w-2/5">
            <div class="h-full grid items-center justify-center">
              <div>
                <span class="text-2xl">Hi, I Am</span><br />
                <div class="text-8xl font-semibold">
                  {{ values.user_name }}
                </div>
              </div>
            </div>
          </div>
          <div class="w-3/5 flex justify-end">
            <img
              class="w-2/3 object-contain"
              ref="profileImage"
              :src="values.profile_picture.url"
              alt=""
            />
          </div>
        </div>
        <div class="w-5/6 bg-nColorMustard p-10 text-white grid justify-center">
          <div class="pos mb-4">
            <p class="mb-2" v-if="values.personal.designation">
              I am a
              <span class="inline-block mx-2 text-center text-xl font-semibold">
                {{ values.personal.designation }}
              </span>
              based out of
              <span class="inline-block mx-2 text-center text-xl font-semibold">
                {{ values.personal.based_out_of }}
              </span>
            </p>
          </div>
          <div class="exp" v-if="values.personal.years_of_exp">
            <p>
              I have
              <span class="inline-block mx-2 text-center text-xl font-semibold">
                {{ values.personal.years_of_exp }}
              </span>
              years of experience and my last sting was at
              <span class="inline-block text-center text-xl font-semibold mx-2">
                {{ values.personal.last_job }}
              </span>
            </p>
          </div>
        </div>
      </div>
      <div id="personal" class="grid mt-4">
        <div class="flex">
          <div class="w-3/5">
            <div class="h-full grid pt-10">
              <div>
                <span class="text-6xl font-semibold">Personal</span>
                <div class="w-full pl-12 float-right">
                  <div
                    class="border-t border-nColorRed border-opacity-35"
                  ></div>
                </div>
                <div class="bg-nColorBlue mt-6 p-10 text-white grid">
                  <p class="inline-block text-xl mx-5">
                    My family consists of
                    <span class="font-semibold">
                      {{ values.personal.family_info }}
                    </span>
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div
            class="w-2/5 flex justify-center border-4 border-nLightGreen mt-16"
          >
            <img
              class="w-2/3 object-contain"
              ref="profileImage"
              :src="values.personal.animation"
              alt=""
            />
          </div>
        </div>
      </div>
      <div id="interests" class="grid mt-4">
        <div class="flex">
          <div class="w-2/5">
            <div class="flex justify-center border-4 border-nLightPurple">
              <img
                class="w-2/3 object-contain"
                ref="profileImage"
                :src="values.interests.animation"
                alt=""
              />
            </div>
          </div>
          <div class="w-3/5">
            <div class="h-full grid pt-10">
              <div class="mt-10">
                <span class="text-6xl font-semibold float-right"
                  >Interests</span
                >
                <div class="w-full pr-12 float-left mb-6">
                  <div
                    class="border-t border-nColorRed border-opacity-35"
                  ></div>
                </div>
                <div class="bg-nColorMustard p-10 text-white grid">
                  <div class="asp mb-4" v-if="values.interests.wanted_to_be">
                    <p>
                      When I was little I wanted to be a
                      <span
                        class="inline-block text-center text-xl font-semibold"
                      >
                        {{ values.interests.wanted_to_be || "Cricketer" }}
                      </span>
                    </p>
                  </div>

                  <div class="learn" v-if="values.interests.learned_last_year">
                    <p>
                      In the last year I learned to
                      <span
                        class="inline-block text-center text-xl font-semibold"
                      >
                        {{
                          values.interests.learned_last_year || "How to dance"
                        }}
                      </span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="favorites" class="grid mt-4">
        <div class="flex">
          <div class="w-3/5">
            <div class="h-full grid pt-10">
              <div>
                <span class="text-6xl font-semibold">Favorites</span>
                <div class="w-full pl-12 float-right">
                  <div
                    class="border-t border-nColorRed border-opacity-35"
                  ></div>
                </div>
                <div class="bg-nColorBlue mt-6 p-10 text-white grid">
                  <div class="book mb-4">
                    <p>
                      Book I love
                      <span
                        class="inline-block text-center text-xl font-semibold"
                      >
                        {{ values.favorites.book }}
                      </span>
                    </p>
                  </div>
                  <div class="binge-tv mb-4" v-if="values.favorites.tv_show">
                    <p>
                      My comfort binge TV show
                      <span
                        class="inline-block text-center text-xl font-semibold"
                      >
                        {{ values.favorites.tv_show }}
                      </span>
                    </p>
                  </div>
                  <div class="food mb-4" v-if="values.favorites.food">
                    <p>
                      My favorite kinda food
                      <span
                        class="inline-block text-center text-xl font-semibold"
                      >
                        {{ values.favorites.food }}
                      </span>
                    </p>
                  </div>
                  <div class="binge-tv mb-4" v-if="values.favorites.cheer_for">
                    <p>
                      I cheer for
                      <span
                        class="inline-block text-center text-xl font-semibold"
                      >
                        {{ values.favorites.cheer_for }}
                      </span>
                    </p>
                  </div>
                  <div class="binge-tv" v-if="values.favorites.song">
                    <p>
                      Listening on Loop
                      <span
                        class="inline-block text-center text-xl font-semibold"
                      >
                        {{ values.favorites.song }}
                      </span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="w-2/5">
            <div class="flex justify-center border-4 border-nLightYellow mt-16">
              <img
                class="w-2/3 object-contain"
                ref="profileImage"
                :src="values.favorites.animation"
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
      <div id="skills" class="grid mt-4">
        <div class="flex">
          <div class="w-2/5 mt-6">
            <div class="flex justify-center border-4 border-nLightPink">
              <img
                class="w-2/3 object-contain"
                ref="profileImage"
                :src="values.skills.animation"
                alt=""
              />
            </div>
          </div>
          <div class="w-3/5">
            <div class="h-full grid pt-10">
              <div class="mt-10">
                <span class="text-6xl font-semibold float-right">Skills</span>
                <div class="w-full pr-12 float-left mb-6">
                  <div
                    class="border-t border-nColorRed border-opacity-35"
                  ></div>
                </div>
                <div class="bg-nColorMustard p-10 text-white grid">
                  <div class="mb-4">
                    <p>
                      No one can beat me at
                      <span
                        class="inline-block text-center text-xl font-semibold"
                      >
                        {{
                          values.skills.unbeatable_at || "Any video game ever"
                        }}
                      </span>
                    </p>
                  </div>
                  <div class="mb-4">
                    <p>
                      I also have mad skills in
                      <span
                        class="inline-block text-center text-xl font-semibold"
                      >
                        {{ values.skills.mad_skills_in || "Table Tennis" }}
                      </span>
                    </p>
                  </div>
                  <div class="mb-4">
                    <p>
                      I am good at playing
                      <span
                        class="inline-block text-center text-xl font-semibold"
                      >
                        {{ values.skills.good_at_playing || "the Piano" }}
                      </span>
                    </p>
                  </div>
                  <div class="speak" v-if="values.skills.languages">
                    <p>
                      and speaking
                      <span
                        class="inline-block text-center text-xl font-semibold"
                      >
                        {{
                          values.skills.languages || "Kannada, Tamil and Hindi"
                        }}
                      </span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="favorites" class="grid mt-4">
        <div class="flex">
          <div class="w-3/5">
            <div class="h-full grid pt-10">
              <div>
                <span class="text-6xl font-semibold">Inspiration</span>
                <div class="w-full pl-12 float-right">
                  <div
                    class="border-t border-nColorRed border-opacity-35"
                  ></div>
                </div>
                <div
                  class="bg-nColorBlue mt-6 px-10 pt-10 py-6 text-white grid"
                >
                  <div class="mb-4" v-if="values.inspiration.all_time_hero">
                    <p>
                      My all time hero is
                      <span
                        class="inline-block text-center text-xl font-semibold"
                      >
                        {{ values.inspiration.all_time_hero || "Spiderman" }}
                      </span>
                    </p>
                  </div>

                  <div
                    class="mb-4"
                    v-if="values.inspiration.cant_live_without_tech"
                  >
                    <p>
                      The tech invention I can't live without is
                      <span
                        class="inline-block text-center text-xl font-semibold"
                      >
                        {{ values.inspiration.cant_live_without_tech }}
                      </span>
                    </p>
                  </div>

                  <div
                    class="mb-4"
                    v-if="values.inspiration.friends_headlines_at"
                  >
                    <p>
                      I will be making friends and headlines at
                      <span
                        class="inline-block text-center text-xl font-semibold"
                      >
                        {{ values.inspiration.friends_headlines_at }}
                      </span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="w-2/5">
            <div class="flex justify-center border-4 border-nLightGreen mt-16">
              <img
                class="w-2/3 object-contain"
                ref="profileImage"
                :src="values.inspiration.animation"
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ApiService from "@/common/api.service";
import { apiResource } from "@/common/app.config";

export default {
  name: "NHAPreviewOption2",
  created() {},
  components: {},
  mounted() {
    const vue = this;
    ApiService.get(apiResource.onboardingKit.experiences.nha.getSampleProfile)
      .then(({ data }) => {
        vue.values = data.data.profile;
      })
      .catch(() => {});
  },
  data() {
    return {
      values: null,
    };
  },
  methods: {},
};
</script>

<style scoped>
.green {
  background-color: #e4f7f4;
}

.yellow {
  background-color: #fff8de;
}

.purple {
  background-color: #e9ecff;
}
.pink {
  background-color: #fff0f5;
}

.solid-green {
  background-color: #11bca2;
}

.solid-pink {
  background-color: #ff5670;
}

.purple-btn {
  color: #7789e0;
}

.yellow-btn {
  color: #d8c27d;
}

.yellow-btn-bg {
  background-color: #d8c27d;
}

.pink-btn {
  color: #d89cae;
}

.yellow-text {
  color: #fdb400;
}

.green-text {
  color: #11bca2;
}

.pink-text {
  color: #ff5670;
}

.purple-text {
  color: #3d50e0;
}

.green-btn {
  color: #85c6ba;
}

.gray {
  background-color: rgba(245, 243, 243, 0.856);
}

.text-color {
  color: #777777;
}

.upload-box {
  width: 300px;
  height: 240px;
}

.round-box {
  width: 450px;
  height: 450px;
}
</style>
