export const messages = {
  inductionVideo: {
    company_name: {
      required: "Enter Company Name",
    },

    revenue: {
      required: "Enter Company Revenue",
    },

    founded_year: {
      required: "Enter age of company since inception",
    },

    locations: {
      required: "Enter at least one location of your office",
    },

    team: {
      required: "Enter the number of employees in your company",
    },

    industry: {
      required: "Enter Industry",
    },

    services: {
      required: "Enter at least one service",
    },

    values: {
      required: "Select at least one value",
    },

    benefits: {
      required: "Select at least one benefit",
    },

    ways_of_working: {
      required: "Select an option",
    },

    resumes: {
      required: "Provide an email address and a website",
    },

    logo: {
      required: "Upload the company logo image",
    },

    office: {
      required: "Upload a video of your office",
    },

    office_youtube: {
      required: "Provide a youtube link for the CEO video ",
    },

    ceo: {
      required: "Upload a video of the CEO",
    },

    ceo_youtube: {
      required: "Provide a youtube link for the CEO video",
    },
    testimonial: {
      required: "Upload a testimonial video",
    },

    testimonial_youtube: {
      required: "Provide a youtube link for the office video",
    },
  },
  hrmsintegrations: {
    url: {
      required: "Please provide a valid URL",
    },
    username: {
      required: "Please provide a valid Username",
    },
    key: {
      required: "Please provide a valid Key",
    },
  },
};
